import { SchemaCodeEnum, FilterOperatorEnum } from "./enum";
import {
  CommonMap,
  LaborContractVO,
  MaterialContractVO,
  SubContractContractVO,
  LaborPaymentVO,
  MaterialPaymentVO,
  SubContractPaymentVO,
  RemediationCostPaymentVO,
  TrivialContractVO,
  MaterialBudgetVO,
  LaborBudgetVO,
  SubcontractVO,
  RemediationCostVO,
  LaborPayVO,
  MaterialPayVO,
  SubContractPayVO,
  RemediationCostPayVO,
} from "./type";

export function sum(p: any, n: any) {
  return (Number(p) || 0) + (Number(n) || 0);
}

export function subtraction(minuend: any, subtrahend: any): number {
  const numberMinuend = Number(minuend);
  const numberSubtrahend = Number(subtrahend);
  if (!isNaN(numberMinuend) && !isNaN(numberSubtrahend)) {
    return numberMinuend - numberSubtrahend;
  }
  return 0;
}

export function division(dividend: any, divisor: any): string {
  const numberDividend = Number(dividend);
  const numberDivisor = Number(divisor);
  if (!isNaN(numberDividend) && !isNaN(numberDivisor) && numberDivisor != 0) {
    return String(Math.round((numberDividend / numberDivisor) * 100)) + "%";
  }
  return "";
}

export function getSchema(type: SchemaCodeEnum): string {
  // const appPrefix = "D285017";
  const appPrefix = "";
  return `${appPrefix}${type}`;
}

export function abstractMap(array: any[], key: string, sort: boolean = false) {
  return array?.reduce((p: any, n: any) => {
    if (!p[n[key]]) {
      p[n[key]] = [];
    }
    p[n[key]] = sort ? [n, ...p[n[key]]] : [...p[n[key]], n];
    return p;
  }, {});
}

export function handleCommanMap(array: any[]) {
  return array
    ?.map((data: any) => ({ [data.ObjectId]: data.F0000001 }))
    ?.reduce((p: CommonMap, n: CommonMap) => {
      return Object.assign(p, n);
    }, {});
}

export function handleGZMap(array: any[]) {
  return array
    ?.map((data: any) => ({ [data.ObjectId]: data.gzmc }))
    ?.reduce((p: CommonMap, n: CommonMap) => {
      return Object.assign(p, n);
    }, {});
}

export function handleMaterialMap(array: any[]) {
  return array
    ?.map((data: any) => ({ [data.ObjectId]: data.cl }))
    ?.reduce((p: CommonMap, n: CommonMap) => {
      return Object.assign(p, n);
    }, {});
}

export function handleZYFBMap(array: any[]) {
  return array
    ?.map((data: any) => ({ [data.ObjectId]: data.fbmc }))
    ?.reduce((p: CommonMap, n: CommonMap) => {
      return Object.assign(p, n);
    }, {});
}

export function handleRemediationCostMap(array: any[]) {
  return array
    ?.map((data: any) => ({ [data.ObjectId]: data.csffl }))
    ?.reduce((p: CommonMap, n: CommonMap) => {
      return Object.assign(p, n);
    }, {});
}

export function handleVendorMap(array: any[]) {
  return array
    ?.map((data: any) => ({ [data.ObjectId]: data.gysmc }))
    ?.reduce((p: CommonMap, n: CommonMap) => {
      return Object.assign(p, n);
    }, {});
}

export function handleLaborContractMap(array: LaborContractVO[]) {
  return array?.reduce((p: any, n: any) => {
    if (!p[n.D275657lwhtmx?.[0].lwys]) {
      p[n.D275657lwhtmx?.[0].lwys] = [];
    }
    p[n.D275657lwhtmx?.[0].lwys] = [n, ...p[n.D275657lwhtmx?.[0].lwys]];
    return p;
  }, {});
}

export function handleMaterialContractMap(array: MaterialContractVO[]) {
  return array?.reduce((p: any, n: any) => {
    if (!p[n.D275657clmx?.[0].clmc]) {
      p[n.D275657clmx?.[0].clmc] = [];
    }
    p[n.D275657clmx?.[0].clmc] = [n, ...p[n.D275657clmx?.[0].clmc]];
    return p;
  }, {});
}

export function handleTrivialContractMap(array: TrivialContractVO[]) {
  return array?.reduce((p: any, n: any) => {
    if (!p[n.D275657clmxb?.[0].clmc]) {
      p[n.D275657clmxb?.[0].clmc] = [];
    }
    p[n.D275657clmxb?.[0].clmc] = [n, ...p[n.D275657clmxb?.[0].clmc]];
    return p;
  }, {});
}

export function handleSubContractMap(array: SubContractContractVO[]) {
  return array?.reduce((p: any, n: any) => {
    if (!p[n.D275657zyfbmx?.[0].cpfbmc]) {
      p[n.D275657zyfbmx?.[0].cpfbmc] = [];
    }
    p[n.D275657zyfbmx?.[0].cpfbmc] = [n, ...p[n.D275657zyfbmx?.[0].cpfbmc]];
    return p;
  }, {});
}

export function handleLaborPayMap(array: LaborPayVO[]) {
  return array?.reduce((p: any, n: any) => {
    if (!p[n.glht]) {
      p[n.glht] = [];
    }
    p[n.glht] = [...p[n.glht], n];
    return p;
  }, {});
}

export function handleMaterialPayMap(array: MaterialPayVO[]) {
  return array?.reduce((p: any, n: any) => {
    if (!p[n.glht]) {
      p[n.glht] = [];
    }
    p[n.glht] = [...p[n.glht], n];
    return p;
  }, {});
}
export function handleSubContractPayMap(array: SubContractPayVO[]) {
  return array?.reduce((p: any, n: any) => {
    if (!p[n.glht]) {
      p[n.glht] = [];
    }
    p[n.glht] = [...p[n.glht], n];
    return p;
  }, {});
}

export function handleRemediationCostPayMap(array: RemediationCostPayVO[]) {
  return array?.reduce((p: any, n: any) => {
    if (!p[n.D275657fkmx?.[0]?.glys]) {
      p[n.D275657fkmx?.[0]?.glys] = [];
    }
    p[n.D275657fkmx?.[0]?.glys] = [...p[n.D275657fkmx?.[0]?.glys], n];
    return p;
  }, {});
}

// 根据支付申请单号映射
export function handleLaborPaymentArrayMap(array: LaborPaymentVO[]) {
  // 支付类
  const dic1 = array
    ?.filter((data) => !!data.zflsqdh)
    ?.reduce((p: any, n: any) => {
      if (!p[n.zflsqdh]) {
        p[n.zflsqdh] = [];
      }
      p[n.zflsqdh] = [...p[n.zflsqdh], n];
      return p;
    }, {});

  return dic1;
}

// 根据材料支付申请单号映射
export function handleMaterialPaymentArrayMap(array: MaterialPaymentVO[]) {
  const dic1 = abstractMap(
    array?.filter((data) => !!data.zflsqdh),
    "zflsqdh"
  );

  const dic2 = abstractMap(
    array?.filter((data) => !!data.lxclsqdh),
    "lxclsqdh"
  );

  return { ...dic1, ...dic2 };
}

// 根据专业分包支付申请单号映射
export function handleSubContractPaymentArrayMap(
  array: SubContractPaymentVO[]
) {
  const dic1 = abstractMap(
    array?.filter((data) => !!data.zflsqdh),
    "zflsqdh"
  );

  return dic1;
}

// 简化Filter写法
export function getFilter(Name: string, Value: string) {
  return {
    Matcher: {
      Type: "And",
      Matchers: [
        {
          Type: "Item",
          Name,
          Operator: FilterOperatorEnum.euqal,
          Value,
        },
      ],
    },
  };
}

// 根据劳务合同结算计算出回挂次数的最大值
export function getLaborMaxPaymentCount(array: LaborPaymentVO[]) {
  const laborMap: { [key: string]: LaborPaymentVO[] } = array?.reduce(
    (p: any, n: any) => {
      if (!p[n.glht]) {
        p[n.glht] = [];
      }
      p[n.glht] = [...p[n.glht], n];
      return p;
    },
    {}
  );
  const laborCountMap: { [key: string]: number } = {};

  Object.keys(laborMap)?.map((key) => {
    const laborArray = laborMap[key];

    const type1 = laborArray.filter((data) => data.fklx === "支付类");
    const type1Map: { [key: string]: LaborPaymentVO[] } = type1?.reduce(
      (p: any, n: any) => {
        if (!p[n.zflsqdh]) {
          p[n.zflsqdh] = [];
        }
        p[n.zflsqdh] = [...p[n.zflsqdh], n];
        return p;
      },
      {}
    );
    const type1CountArray: number[] = Object.values(type1Map)?.map(
      (data) => data.length
    );
    const type2 = laborArray.filter((data) => data.fklx === "结算类");
    const type2Map: { [key: string]: LaborPaymentVO[] } = type2?.reduce(
      (p: any, n: any) => {
        if (!p[n.jslfkdh]) {
          p[n.jslfkdh] = [];
        }
        p[n.jslfkdh] = [...p[n.jslfkdh], n];
        return p;
      },
      {}
    );
    const type2CountArray: number[] = Object.values(type2Map)?.map(
      (data) => data.length
    );
    const type5 = laborArray.filter((data) => data.fklx === "质保金退回");
    const type5Map: { [key: string]: LaborPaymentVO[] } = type5?.reduce(
      (p: any, n: any) => {
        if (!p[n.zbjgldh]) {
          p[n.zbjgldh] = [];
        }
        p[n.zbjgldh] = [...p[n.zbjgldh], n];
        return p;
      },
      {}
    );
    const type5CountArray: number[] = Object.values(type5Map)?.map(
      (data) => data.length
    );
    laborCountMap[key] = Math.max(
      Math.max(...type1CountArray),
      Math.max(...type2CountArray),
      Math.max(...type5CountArray)
    );
  });
  return laborCountMap;
}

// 根据材料合同计算出回挂次数的最大值
export function getMaterialMaxPaymentCount(array: MaterialPaymentVO[]) {
  const materialMap: { [key: string]: MaterialPaymentVO[] } = abstractMap(
    array,
    "glht"
  );
  const materialCountMap: { [key: string]: number } = {};
  Object.keys(materialMap)
    ?.filter((key) => !!key)
    ?.map((key) => {
      const materialArray = materialMap[key];

      const type1 = materialArray.filter((data) => data.fklx === "支付类");
      const type1Map: { [key: string]: MaterialPaymentVO[] } = abstractMap(
        type1,
        "zflsqdh"
      );
      const type1CountArray: number[] = Object.values(type1Map)?.map(
        (data) => data.length
      );
      const type2 = materialArray.filter((data) => data.fklx === "结算类");
      const type2Map: { [key: string]: MaterialPaymentVO[] } = abstractMap(
        type2,
        "jslfkdh"
      );
      const type2CountArray: number[] = Object.values(type2Map)?.map(
        (data) => data.length
      );

      const type4 = materialArray.filter((data) => data.fklx === "质保金退回");
      const type4Map: { [key: string]: MaterialPaymentVO[] } = abstractMap(
        type4,
        "zbjsqdh"
      );
      const type4CountArray: number[] = Object.values(type4Map)?.map(
        (data) => data.length
      );

      materialCountMap[key] = Math.max(
        Math.max(...type1CountArray),
        Math.max(...type2CountArray),
        Math.max(...type4CountArray)
      );
    });
  return materialCountMap;
}

// 根据专业分包合同计算出回挂次数的最大值
export function getSubContractMaxPaymentCount(array: SubContractPaymentVO[]) {
  const subContractMap: { [key: string]: SubContractPaymentVO[] } = abstractMap(
    array,
    "glht"
  );
  const subContractCountMap: { [key: string]: number } = {};
  Object.keys(subContractMap)?.map((key) => {
    const subContractArray = subContractMap[key];

    const type1 = subContractArray.filter((data) => data.fklx === "支付类");
    const type1Map: { [key: string]: SubContractPaymentVO[] } = abstractMap(
      type1,
      "zflsqdh"
    );
    const type1CountArray: number[] = Object.values(type1Map)?.map(
      (data) => data.length
    );
    const type2 = subContractArray.filter((data) => data.fklx === "结算类");
    const type2Map: { [key: string]: SubContractPaymentVO[] } = abstractMap(
      type2,
      "kslfkdh"
    );
    const type2CountArray: number[] = Object.values(type2Map)?.map(
      (data) => data.length
    );

    const type4 = subContractArray.filter((data) => data.fklx === "质保金退回");
    const type4Map: { [key: string]: SubContractPaymentVO[] } = abstractMap(
      type4,
      "sbjgldh"
    );
    const type4CountArray: number[] = Object.values(type4Map)?.map(
      (data) => data.length
    );
    subContractCountMap[key] = Math.max(
      Math.max(...type1CountArray),
      Math.max(...type2CountArray),
      Math.max(...type4CountArray)
    );
  });
  return subContractCountMap;
}

// 根据措施费合同计算出回挂次数的最大值
export function getRCMaxPaymentCount(array: RemediationCostPaymentVO[]) {
  const rcMap: { [key: string]: RemediationCostPaymentVO[] } = array?.reduce(
    (p: any, n: any) => {
      if (!p[n.D275657fkmxb?.[0].glys]) {
        p[n.D275657fkmxb?.[0].glys] = [];
      }
      p[n.D275657fkmxb?.[0].glys] = [...p[n.D275657fkmxb?.[0].glys], n];
      return p;
    },
    {}
  );

  const rcCountMap: { [key: string]: number } = {};

  Object.keys(rcMap)?.map((key) => {
    const subContractArray = rcMap[key];

    const type1 = subContractArray;
    const type1Map: { [key: string]: RemediationCostPaymentVO[] } = abstractMap(
      type1,
      "fksqdhzc"
    );
    const type1CountArray: number[] = Object.values(type1Map)?.map(
      (data) => data.length
    );
    rcCountMap[key] = Math.max(Math.max(...type1CountArray));
  });

  return rcCountMap;
}

// 标红
export function isRedHighlight(isHighlight: boolean) {
  return { color: isHighlight ? "red" : "black" };
}

export function isBlueHighlight(isHighlight: boolean) {
  return { color: isHighlight ? "blue" : "black" };
}

// 劳务类累计产值汇总
export function laborAccumulatedMap(map: any) {
  const array = Object.values(map)
    ?.flatMap((array) => array)
    ?.map((data: any) => ({ [data.glht]: data.ljyfwcz }));

  const dic: { [key: string]: number } = {};
  array?.map((data) => {
    if (dic.hasOwnProperty(Object.keys(data)?.[0])) {
      if (Object.values(data)?.[0] > dic[Object.keys(data)?.[0]]) {
        dic[Object.keys(data)?.[0]] = Object.values(data)?.[0];
      }
    } else {
      dic[Object.keys(data)?.[0]] = Object.values(data)?.[0];
    }
  });
  return dic;
}

export function materialAccumulatedMap(map: any) {
  const array = Object.values(map)
    ?.flatMap((array) => array)
    ?.filter((data: any) => !!data.ljqrshje)
    ?.map((data: any) => ({ [data.glht]: data.ljqrshje }));
  const dic: { [key: string]: number } = {};
  array?.map((data) => {
    if (dic.hasOwnProperty(Object.keys(data)?.[0])) {
      if (Object.values(data)?.[0] > dic[Object.keys(data)?.[0]]) {
        dic[Object.keys(data)?.[0]] = Object.values(data)?.[0];
      }
    } else {
      dic[Object.keys(data)?.[0]] = Object.values(data)?.[0];
    }
  });
  return dic;
}

export function subAccumulatedMap(map: any) {
  const array = Object.values(map)
    ?.flatMap((array) => array)
    ?.filter((data: any) => !!data.ljywccz)
    ?.map((data: any) => ({ [data.glht]: data.ljywccz }));
  const dic: { [key: string]: number } = {};
  array?.map((data) => {
    if (dic.hasOwnProperty(Object.keys(data)?.[0])) {
      if (Object.values(data)?.[0] > dic[Object.keys(data)?.[0]]) {
        dic[Object.keys(data)?.[0]] = Object.values(data)?.[0];
      }
    } else {
      dic[Object.keys(data)?.[0]] = Object.values(data)?.[0];
    }
  });
  return dic;
}

export function handleRCMap(array: RemediationCostPaymentVO[]) {
  const dic1 = abstractMap(
    array?.filter((data) => !!data.fksqdhzc),
    "fksqdhzc",
    true
  );

  return dic1;
}

// 劳务预计结算金额汇总
export function getLaborEstimatedSettlementAmount(
  array1: LaborBudgetVO[],
  array2: LaborContractVO[]
) {
  return array1
    ?.map((budget) => {
      const budgetName = budget.Name;
      const isFinal = budget.sfqbqy;
      const budgetAmount = budget.ysje;
      const filterContracts = array2?.filter((contract) => {
        return budgetName === contract?.D275657lwhtmx?.[0]?.Name;
      });
      // 已结算的金额汇总
      const settleAmount = filterContracts
        ?.map((data) => data.jsje)
        ?.reduce(sum, 0);
      // 未结算的合同金额汇总
      const contractAmount = filterContracts
        ?.filter((data) => !data.jsje)
        ?.map((data) => data.htze)
        ?.reduce(sum, 0);

      if (isFinal === "是") {
        return settleAmount + contractAmount;
      } else {
        return Math.max(budgetAmount, settleAmount + contractAmount);
      }
    })
    ?.reduce(sum, 0);
}

// 材料预计结算金额汇总
export function getMaterialEstimatedSettlementAmount(
  array1: MaterialBudgetVO[],
  array2: MaterialContractVO[],
  array3: TrivialContractVO[]
) {
  return array1
    ?.map((budget, index) => {
      // 筛选出与预算对应的合同
      const budgetName = budget.Name;
      const isFinal = budget.clsfyqbqd;
      const budgetAmount = budget.clysje;
      const filterContracts = array2?.filter((contract) => {
        return budgetName === contract?.D275657clmx?.[0]?.Name;
      });
      const filterTrivialContracts = array3?.filter((data) => {
        return budgetName === data?.D275657clmxb?.[0]?.Name;
      });
      // 零星采购逻辑
      if (filterTrivialContracts?.length > 0) {
        const actualAmout = filterTrivialContracts
          ?.map((data) => data?.D275657clmxb?.[0]?.bcje)
          ?.reduce(sum, 0);

        return isFinal === "是"
          ? actualAmout
          : Math.max(budgetAmount, actualAmout);
      }
      // 已结算的金额汇总
      const settleAmount = filterContracts
        ?.map((data) => data.jsje)
        ?.reduce(sum, 0);
      // 未金额的合同金额汇总
      const contractAmount = filterContracts
        ?.filter((data) => !data.jsje)
        ?.map((data) => data.htze)
        ?.reduce(sum, 0);

      if (isFinal === "是") {
        return settleAmount + contractAmount;
      } else {
        return Math.max(budgetAmount, settleAmount + contractAmount);
      }
    })
    ?.reduce(sum, 0);
}

// 专业分包预计结算金额汇总
export function getSubContractEstimatedSettlementAmount(
  array1: SubcontractVO[],
  array2: SubContractContractVO[]
) {
  return array1
    ?.map((budget) => {
      const budgetName = budget.Name;
      const isFinal = budget.fbsfqbqy;
      const budgetAmount = budget.fbysje;
      const filterContracts = array2?.filter((contract) => {
        return budgetName === contract?.D275657zyfbmx?.[0]?.Name;
      });
      // 已结算的金额汇总
      const settleAmount = filterContracts
        ?.map((data) => data.jsje)
        ?.reduce(sum, 0);
      // 未金额的合同金额汇总
      const contractAmount = filterContracts
        ?.filter((data) => !data.jsje)
        ?.map((data) => data.htze)
        ?.reduce(sum, 0);

      if (isFinal === "是") {
        return settleAmount + contractAmount;
      } else {
        return Math.max(budgetAmount, settleAmount + contractAmount);
      }
    })
    ?.reduce(sum, 0);
}

// 措施费预计结算金额汇总
export function getOtherEstimatedSettlementAmount(
  array1: RemediationCostVO[],
  array2: RemediationCostPayVO[]
) {
  return array1
    ?.map((budget) => {
      const budgetName = budget.Name;
      const isFinal = budget.csfsfyqbqy;
      const budgetAmount = budget.csysje;

      // 当前budget对应的措施费过程款
      const filterArray = array2?.filter(
        (data) => budgetName === data?.D275657fkmx?.[0]?.Name
      );
      // 实批金额汇总
      const actualAmout = filterArray
        ?.map((data) => data?.D275657fkmx?.[0]?.bcje)
        ?.reduce(sum, 0);
      // 如果没有实批，就用预算金额
      if (actualAmout === 0) {
        return budgetAmount;
      }

      // 如果是最后一次批，就用实批金额汇总，否则就比较预算金额和实批汇总大小
      if (isFinal === "是") {
        return actualAmout;
      } else {
        return Math.max(budgetAmount, actualAmout);
      }
    })
    ?.reduce(sum, 0);
}

// 数字纠偏
export function correct(target: number, referent: number) {
  if (!target) {
    return 0;
  }

  if (Math.abs(subtraction(target, referent)) / referent < 0.001) {
    return Math.round(referent);
  }
  return Math.round(target);
}

// 实付金额（劳务汇总计算）
export function getLaborActualPaymentAmountSum(array: LaborPaymentVO[]) {
  const map: LaborPaymentVO[][] = Object.values(abstractMap(array, "Name"));

  return map
    ?.map((data) => {
      const amount = data?.[0]?.bcpkje;
      return correct(
        data
          ?.map((datum) =>
            datum.fklx === "支付类" ? datum.fkblhs : datum.bcfkje
          )
          ?.reduce(sum, 0),
        amount
      );
    })
    ?.reduce(sum, 0);
}

// 实付金额（材料汇总计算）
export function getMaterialActualPaymentAmountSum(array: MaterialPaymentVO[]) {
  const map: MaterialPaymentVO[][] = Object.values(abstractMap(array, "Name"));

  return map
    ?.map((data) => {
      const amount = data?.[0]?.bcpk;
      return correct(
        data
          ?.map((datum) =>
            datum.fklx === "支付类" || datum.fklx === "零星材料类"
              ? datum.fkblhs
              : datum.bcfkje
          )
          ?.reduce(sum, 0),
        amount
      );
    })
    ?.reduce(sum, 0);
}

// 实付金额（分包汇总计算）
export function getSubActualPaymentAmountSum(array: SubContractPaymentVO[]) {
  const map: SubContractPaymentVO[][] = Object.values(
    abstractMap(array, "Name")
  );

  return map
    ?.map((data) => {
      const amount = data?.[0]?.bcpkje;
      return correct(
        data
          ?.map((datum) =>
            datum.fklx === "支付类" ? datum.fkblhs : datum.bcfkje
          )
          ?.reduce(sum, 0),
        amount
      );
    })
    ?.reduce(sum, 0);
}
