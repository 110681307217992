// 合并相同键的词典
export function mergeMap(...args: any) {
  return args.reduce((prev: any, next: any) => {
    Object.keys(next).map((key: string) => {
      prev[key] = prev[key] ? prev[key].concat(next[key]) : next[key];
    });
    return prev;
  }, {});
}

// 获取词典中values数组length最大的项
export function getMaxCount(dic: any): number {
  if (dic && Object.values(dic).length > 0) {
    return Math.max.apply(
      null,
      Object.values(dic).map((data: any) => data.length)
    );
  }
  return 0;
}
