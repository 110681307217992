import { Fragment, useEffect, useState, MouseEvent } from "react";
import Head from "./components/Head";
import Row from "./components/Row";
import SubRow from "./components/SubRow";
import Link from "./components/Link";
import { getSingleItem, getItems } from "./services/api";
import { Form, Select, Button, SelectValue } from "tdesign-react";
import "tdesign-react/es/style/index.css";
import {
  LaborBudgetVO,
  MaterialBudgetVO,
  SubcontractVO,
  RemediationCostVO,
  ProjectOptionVO,
  ProjectArchiveVO,
  CommonMap,
  LaborContractMap,
  MaterialContractMap,
  SubContractContractMap,
  LaborSettlementVO,
  LaborPaymentVO,
  LaborPayMap,
  MaterialPayMap,
  SubContractPayMap,
  RemediationCostPayMap,
  MaterialPaymentVO,
  SubContractPaymentVO,
  RemediationCostPaymentVO,
  ApprovedVO,
  LaborPaybackVO,
  MaterialPaybackVO,
  SubContractPaybackVO,
  MaterialSettlementVO,
  SubContractSettlementVO,
  TrivialContractMap,
  MaterialContractVO,
  LaborContractVO,
  SubContractContractVO,
  TrivialContractVO,
  RemediationCostPayVO,
  DJContractVO,
} from "./type";
import { SchemaCodeEnum, LinkEnum } from "./enum";
import {
  getSchema,
  sum,
  division,
  correct,
  handleCommanMap,
  handleLaborContractMap,
  handleMaterialContractMap,
  handleSubContractMap,
  handleLaborPayMap,
  handleMaterialPayMap,
  handleSubContractPayMap,
  handleRemediationCostPayMap,
  handleLaborPaymentArrayMap,
  handleMaterialPaymentArrayMap,
  handleSubContractPaymentArrayMap,
  getFilter,
  getLaborMaxPaymentCount,
  getMaterialMaxPaymentCount,
  getSubContractMaxPaymentCount,
  getRCMaxPaymentCount,
  isRedHighlight,
  laborAccumulatedMap,
  materialAccumulatedMap,
  subAccumulatedMap,
  handleRCMap,
  handleTrivialContractMap,
  getLaborEstimatedSettlementAmount,
  getMaterialEstimatedSettlementAmount,
  getSubContractEstimatedSettlementAmount,
  getOtherEstimatedSettlementAmount,
  getLaborActualPaymentAmountSum,
  getMaterialActualPaymentAmountSum,
  getSubActualPaymentAmountSum,
  handleGZMap,
  handleMaterialMap,
  handleZYFBMap,
  handleRemediationCostMap,
  handleVendorMap,
} from "./util";
import { getMaxCount, mergeMap } from "./utils/progress";
import "./App.css";

const { FormItem } = Form;

function App() {
  const {
    REACT_APP_LABOR_BUDGET,
    REACT_APP_MATERIAL_BUDGET,
    REACT_APP_SUB_BUDGET,
    REACT_APP_OTHER_BUDGET,
  } = process.env;

  const [currentObjectId, setCurrentObjectId] = useState(
    "6f23b630-8b3e-465c-883c-a1a6aa4beeb0"
  );
  // 控制Head显示/隐藏
  const [showHead, setShowHead] = useState(false);
  // 选项Option
  const [projectOptions, setProjectOptions] = useState<ProjectOptionVO[]>([]);
  // 项目建档
  const [projectArchive, setProjectArchive] = useState<ProjectArchiveVO>(
    {} as any
  );
  // 劳务预算
  const [laborBudgets, setLaborBudgets] = useState<LaborBudgetVO[]>([]);
  // 材料预算
  const [materialBudgets, setMaterialBudgets] = useState<MaterialBudgetVO[]>(
    []
  );
  // 成品发包预算
  const [subBudgets, setSubBudgets] = useState<SubcontractVO[]>([]);
  // 措施费预算
  const [otherBudgets, setOtherBudgets] = useState<RemediationCostVO[]>([]);

  // 对甲合同
  const [djContract, setDjContract] = useState<DJContractVO>({} as any);

  const [occupationsMap, setOccupationsMap] = useState<CommonMap>({} as any);
  const [materialMap, setMaterialMap] = useState<CommonMap>({} as any);
  const [subContractMap, setSubContractMap] = useState<CommonMap>({} as any);
  const [remediationCostMap, setRemediationCostMap] = useState<CommonMap>(
    {} as any
  );
  // 供应商档案映射
  const [supplierMap, setSupplierMap] = useState<CommonMap>({} as any);

  // 合同签订（劳务）
  const [laborContractMap, setLaborContractMap] = useState<LaborContractMap>(
    {} as any
  );

  // 合同签订（材料）
  const [materialContractMap, setMaterialContractMap] =
    useState<MaterialContractMap>({} as any);
  // 合同签订（零星）
  const [trivialContractMap, setTrivialContractMap] =
    useState<TrivialContractMap>({} as any);
  // 合同签订（专业分包）
  const [subContractContractMap, setSubContractContractMap] =
    useState<SubContractContractMap>({} as any);

  // 劳务类支付
  const [laborPayMap, setLaborPayMap] = useState<LaborPayMap>({} as any);

  // 材料类支付
  const [materialPayMap, setMaterialPayMap] = useState<MaterialPayMap>(
    {} as any
  );

  // 专业分包支付
  const [subContractPayMap, setSubContractPayMap] = useState<SubContractPayMap>(
    {} as any
  );

  // 措施费支付
  const [remediationCostPayMap, setRemediationCostPayMap] =
    useState<RemediationCostPayMap>({} as any);

  // 结算申请（劳务）
  const [laborSettlementArray, setLaborSettlementArray] =
    useState<LaborSettlementVO[]>();

  // 结算申请（材料）
  const [materialSettlementArray, setMaterialSettlementArray] = useState<
    MaterialSettlementVO[]
  >([]);
  // 结算申请（专业分包）
  const [subContractSettlementArray, setSubContractSettlementArray] = useState<
    SubContractSettlementVO[]
  >([]);

  // 劳务付款登记数组
  const [laborPaymentArray, setLaborPaymentArray] = useState<LaborPaymentVO[]>(
    []
  );
  // 质保金退回（劳务）
  const [laborPaybackArray, setLaborPaybackArray] = useState<LaborPaybackVO[]>(
    []
  );
  // 质保金退回（材料）
  const [materialPaybackArray, setMaterialPaybackArray] = useState<
    MaterialPaybackVO[]
  >([]);
  // 质保金退回（专业分包）
  const [subContractPaybackArray, setSubContractPaybackArray] = useState<
    SubContractPaybackVO[]
  >([]);
  const [materialPaymentArray, setMaterialPaymentArray] =
    useState<MaterialPaymentVO[]>();
  const [subContractPaymentArray, setSubContractPaymentArray] =
    useState<SubContractPaymentVO[]>();
  const [remediationCostPaymentArray, setRemediationCostPaymentArray] =
    useState<RemediationCostPaymentVO[]>();

  // 合同id：最大劳务回挂次数
  const [maxLaborPaymentCountMap, setMaxLaborPaymentCountMap] = useState<{
    [key: string]: number;
  }>();
  // 合同id：最大材料回挂次数
  const [maxMaterialPaymentCountMap, setMaxMaterialPaymentCountMap] = useState<{
    [key: string]: number;
  }>();
  // 合同id：最大分包回挂次数
  const [maxSubContractPaymentCountMap, setMaxSubContractPaymentCountMap] =
    useState<{ [key: string]: number }>();
  // 合同id：最大措施费回挂次数
  const [maxRCPaymentCountMap, setMaxRCPaymentCountMap] = useState<{
    [key: string]: number;
  }>();

  // 措施费结算申请单号（正常）到回挂的映射
  const [rcPaymentMap, setRCPaymentMap] = useState<{
    [key: string]: RemediationCostPaymentVO[];
  }>();

  // 劳务合同
  const [laborContracts, setLaborContracts] = useState<LaborContractVO[]>([]);

  // 材料合同
  const [materialContracts, setMaterialContracts] = useState<
    MaterialContractVO[]
  >([]);

  // 零星合同
  const [trivialContracts, setTrivialContracts] = useState<TrivialContractVO[]>(
    []
  );

  // 专业分包合同
  const [subContracts, setSubContracts] = useState<SubContractContractVO[]>([]);

  // 措施费数组（措施费支付正常）
  const [others, setOthers] = useState<RemediationCostPayVO[]>([]);

  useEffect(() => {
    document.title = "秋元华林";

    // 获取当前所有的项目
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.xmjdSchemaCode),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      setProjectOptions(
        array.map((data: ProjectArchiveVO) => ({
          label: data.xmmc,
          value: data.ObjectId,
        }))
      );
    });

    loadData();
  }, [currentObjectId]);

  const loadData = () => {
    // 设置供应商档案
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.gysdaSchemaCode),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      setSupplierMap(handleVendorMap(array));
    });

    // 设置工种分类映射
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.gzflSchemaCode),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      setOccupationsMap(handleGZMap(array));
    });

    // 设置材料分类映射
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.clflSchemaCode),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      setMaterialMap(handleMaterialMap(array));
    });

    // 设置专业分包分类
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.zyfbSchemaCode),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      setSubContractMap(handleZYFBMap(array));
    });

    // 设置措施费分类
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.csfSchemaCode),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      setRemediationCostMap(handleRemediationCostMap(array));
    });

    getSingleItem({
      SchemaCode: getSchema(SchemaCodeEnum.xmjdSchemaCode),
      BizObjectId: currentObjectId,
    }).then((res) => {
      if (res.status === 200) {
        setProjectArchive(res.data.ReturnData.BizObject);
        getSubTable(currentObjectId);
      }
    });
  };

  const getSubTable = async (objectId: string) => {
    // 获取项目预算设立中对应的工种
    getDJContrct(objectId);
    await getProjectBudget(objectId);
    getContract1(objectId);
    getContract2(objectId);
    getContract2plus(objectId);
    getContract3(objectId);
    getPay1(objectId);
    getPay2(objectId);
    getPay3(objectId);
    getPay4(objectId);
    // 获取劳务付款登记
    getPayment1(objectId);
    getPayment2(objectId);
    getPayment3(objectId);
    getPayment4(objectId);
    getSettlement1(objectId);
    getSettlement2(objectId);
    getSettlement3(objectId);
    getPayback1(objectId);
    getPayback2(objectId);
    getPayback3(objectId);
  };

  // 获取对甲合同
  const getDJContrct = async (objectId: string) => {
    try {
      const res = await getItems({
        SchemaCode: getSchema(SchemaCodeEnum.djSchemaCode),
        Filter: getFilter("xmmc", objectId),
      });
      const djContract = res?.data?.ReturnData?.BizObjectArray?.[0];
      setDjContract(djContract);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectBudget = async (parentId: string) => {
    try {
      const res = await getItems({
        SchemaCode: getSchema(SchemaCodeEnum.xmysslSchemaCode),
        Filter: getFilter("xmmc", parentId),
      });
      const projectBudget = res?.data?.ReturnData?.BizObjectArray?.[0];
      setLaborBudgets(
        projectBudget?.[REACT_APP_LABOR_BUDGET || ""]?.filter(
          (data: LaborBudgetVO) => !!data.gz
        )
      );
      setMaterialBudgets(
        projectBudget?.[REACT_APP_MATERIAL_BUDGET || ""]?.filter(
          (data: MaterialBudgetVO) => !!data.cl
        )
      );
      setSubBudgets(
        projectBudget?.[REACT_APP_SUB_BUDGET || ""]?.filter(
          (data: SubcontractVO) => !!data.zyfb
        )
      );
      setOtherBudgets(
        projectBudget?.[REACT_APP_OTHER_BUDGET || ""]?.filter(
          (data: RemediationCostVO) => !!data.csf
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  // 获取对应的合同签订（劳务）
  const getContract1 = (objectId: string) => {
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.htqdlwSchemaCode),
      Filter: getFilter("ssxm", objectId),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      setLaborContractMap(handleLaborContractMap(array));
      setLaborContracts(array);
    });
  };

  // 获取对应的材料合同
  const getContract2 = (objectId: string) => {
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.htqdclSchemaCode),
      Filter: getFilter("ssxm", objectId),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      setMaterialContractMap(handleMaterialContractMap(array));
      setMaterialContracts(array);
    });
  };

  // 获取对应零星采购合同
  const getContract2plus = (objectId: string) => {
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.cllxcgSchemaCode),
      Filter: getFilter("ssxm", objectId),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      setTrivialContractMap(handleTrivialContractMap(array));
      setTrivialContracts(array);
    });
  };

  // 获取对应的专业分包合同
  const getContract3 = (objectId: string) => {
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.htqdzyfbSchemaCode),
      Filter: getFilter("ssxm", objectId),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      setSubContractContractMap(handleSubContractMap(array));
      setSubContracts(array);
    });
  };

  // 获取劳务类支付申请
  const getPay1 = (objectId: string) => {
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.lwlzfsqSchemaCode),
      Filter: getFilter("xmmc", objectId),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      setLaborPayMap(handleLaborPayMap(array));
    });
  };

  // 材料类支付
  const getPay2 = async (objectId: string) => {
    try {
      const res = await getItems({
        SchemaCode: getSchema(SchemaCodeEnum.cllzfsqSchemaCode),
        Filter: getFilter("ssxm", objectId),
      });
      setMaterialPayMap(
        handleMaterialPayMap(res?.data?.ReturnData?.BizObjectArray || [])
      );
    } catch (error) {}
  };

  // 专业分包支付
  const getPay3 = async (objectId: string) => {
    try {
      const res = await getItems({
        SchemaCode: getSchema(SchemaCodeEnum.zyfblzfsqSchemaCode),
        Filter: getFilter("ssxm", objectId),
      });
      setSubContractPayMap(
        handleSubContractPayMap(res?.data?.ReturnData?.BizObjectArray || [])
      );
    } catch (error) {}
  };

  // 措施费支付
  const getPay4 = async (objectId: string) => {
    try {
      const res = await getItems({
        SchemaCode: getSchema(SchemaCodeEnum.csfzfsqSchemaCode),
        Filter: getFilter("ssxm", objectId),
      });
      setRemediationCostPayMap(
        handleRemediationCostPayMap(res?.data?.ReturnData?.BizObjectArray || [])
      );
    } catch (error) {}
  };

  // 获取劳务付款登记
  const getPayment1 = (objectId: string) => {
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.lwfkdjSchemaCode),
      Filter: getFilter("ssxm", objectId),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      setLaborPaymentArray(array);
      setMaxLaborPaymentCountMap(getLaborMaxPaymentCount(array));
    });
  };

  // 获取材料付款登记
  const getPayment2 = (objectId: string) => {
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.clfkdjSchemaCode),
      Filter: getFilter("ssxm", objectId),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      setMaterialPaymentArray(array);
      setMaxMaterialPaymentCountMap(getMaterialMaxPaymentCount(array));
    });
  };

  // 获取专业分包付款登记
  const getPayment3 = (objectId: string) => {
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.zyfbfkdjSchemaCode),
      Filter: getFilter("ssxm", objectId),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      setSubContractPaymentArray(array);
      setMaxSubContractPaymentCountMap(getSubContractMaxPaymentCount(array));
    });
  };

  // 获取措施费付款登记
  const getPayment4 = (objectId: string) => {
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.csffkdjSchemaCode),
      Filter: getFilter("ssxm", objectId),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray || [];

      setRemediationCostPaymentArray(array);
      // 根据付款申请单号（正常）来映射
      setRCPaymentMap(handleRCMap(array));
      setMaxRCPaymentCountMap(getRCMaxPaymentCount(array));
    });
  };

  // 获取项目结算申请（劳务）
  const getSettlement1 = (objectId: string) => {
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.xmjssqlwSchemaCode),
      Filter: getFilter("ssxm", objectId),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      setLaborSettlementArray(array);
    });
  };

  // 获取项目结算申请（材料）
  const getSettlement2 = async (objectId: string) => {
    try {
      const res = await getItems({
        SchemaCode: getSchema(SchemaCodeEnum.xmjssqclSchemaCode),
        Filter: getFilter("ssxm", objectId),
      });
      setMaterialSettlementArray(res?.data?.ReturnData?.BizObjectArray || []);
    } catch (error) {}
  };

  // 获取项目结算申请（专业分包）
  const getSettlement3 = async (objectId: string) => {
    try {
      const res = await getItems({
        SchemaCode: getSchema(SchemaCodeEnum.xmjssqzyfbSchemaCode),
        Filter: getFilter("ssxm", objectId),
      });
      setSubContractSettlementArray(
        res?.data?.ReturnData?.BizObjectArray || []
      );
    } catch (error) {}
  };

  // 获取质保金退回（劳务）
  const getPayback1 = (objectId: string) => {
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.lwzbjthSchemaCode),
      Filter: getFilter("ssxm", objectId),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      setLaborPaybackArray(array);
    });
  };

  // 获取质保金退回（材料）
  const getPayback2 = (objectId: string) => {
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.clzbjthSchemaCode),
      Filter: getFilter("ssxm", objectId),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      setMaterialPaybackArray(array);
    });
  };

  // 获取质保金退回（专业分包）
  const getPayback3 = (objectId: string) => {
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.zyfbzbjthSchemaCode),
      Filter: getFilter("ssxm", objectId),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      setSubContractPaybackArray(array);
    });
  };

  // 劳务预算汇总
  const budgetSum1 = laborBudgets?.map((data) => data.ysje)?.reduce(sum, 0);
  // 材料预算汇总
  const budgetSum2 = materialBudgets
    ?.map((data) => data.clysje)
    ?.reduce(sum, 0);
  // 分包预算汇总
  const budgetSum3 = subBudgets?.map((data) => data.fbysje)?.reduce(sum, 0);
  // 措施费汇总
  const budgetSum4 = otherBudgets?.map((data) => data.csysje)?.reduce(sum, 0);
  // 总汇总
  const budgetSum = budgetSum1 + budgetSum2 + budgetSum3 + budgetSum4;
  // 劳务签约额汇总
  const contractSum1 = laborBudgets?.map((data) => data.yqdje)?.reduce(sum, 0);
  // 材料签约额汇总
  const contractSum2 = materialBudgets
    ?.map((data) => data.clyqdje)
    ?.reduce(sum, 0);
  // 分包签约额汇总
  const contractSum3 = subBudgets?.map((data) => data.fbyqdje)?.reduce(sum, 0);

  // 合并计算支付期
  const maxCount1 = getMaxCount(laborPayMap);
  // 材料最大支付期
  const maxCount2 = getMaxCount(materialPayMap);
  // 专业发包最大支付期
  const maxCount3 = getMaxCount(subContractPayMap);
  // 措施费最大支付期
  const maxCount4 = getMaxCount(remediationCostPayMap);

  const maxCount = Math.max(maxCount1, maxCount2, maxCount3, maxCount4, 3);
  const maxArray = Array(maxCount).fill("");

  // 累计产值(劳务汇总)
  // const laborProgressAccumulatedMap = laborAccumulatedMap(laborProgressMap);
  // const laborSalaryAccumulatedMap = laborAccumulatedMap(laborSalaryMap);
  // const laborLoanAccumulatedMap = laborAccumulatedMap(laborLoanMap);
  const laborPayAccumulatedMap = laborAccumulatedMap(laborPayMap);

  // 汇总所有合同的key
  const laborContractIds = Array.from(
    new Set([...Object.keys(laborPayAccumulatedMap)])
  );
  const laborDic: { [key: string]: number } = {};
  laborContractIds?.map((id) => {
    const max1 = laborPayAccumulatedMap?.[id] || 0;
    laborDic[id] = Math.max(max1);
  });

  const laborAccumulatedValue = Object.values(laborDic)?.reduce(sum, 0);

  // 实批金额(劳务汇总)
  const laborActualValue = Object.values(laborPayMap)
    ?.flatMap((array) => array)
    ?.map((data) => Number(data.bcpk))
    ?.filter((data) => !!data)
    ?.reduce(sum, 0);

  // 实付金额(劳务汇总)
  const lwsfje = getLaborActualPaymentAmountSum(
    laborPaymentArray?.filter((data) => data.fklx === "支付类")
  );

  // 累计送货(材料汇总 过程款和借款的累计确认送货金额的最大值 + 零星材料的实批金额)
  const materialPayAccumulatedMap = materialAccumulatedMap(materialPayMap);

  // 获取所有材料的id
  const materialContractIds = Array.from(
    new Set([...Object.keys(materialPayAccumulatedMap)])
  );
  const materialDic: { [key: string]: number } = {};
  materialContractIds?.map((id) => {
    const max1 = materialPayAccumulatedMap?.[id] || 0;

    materialDic[id] = Math.max(max1);
  });

  // 零星材料（实批金额）
  const materialTrivalActualValue =
    Object.values(trivialContractMap)
      ?.flatMap((array) => array)
      ?.map((data) => Number(data.bcfkje))
      ?.filter((data) => !!data)
      ?.reduce(sum, 0) || 0;

  const ljsh =
    Object.values(materialDic)?.reduce(sum, 0) + materialTrivalActualValue;

  // 实批金额(材料汇总)
  const materialPayActualValue = Object.values(materialPayMap)
    ?.flatMap((array) => array)
    ?.map((data) => Number(data.bcpkje))
    ?.filter((data) => !!data)
    ?.reduce(sum, 0);

  const clspje = materialPayActualValue + materialTrivalActualValue;
  // 实付金额(材料汇总)
  const clsfje = getMaterialActualPaymentAmountSum(
    materialPaymentArray?.filter(
      (data) => data.fklx === "支付类" || data.fklx === "零星材料类"
    ) || []
  );

  // 预计结算金额（劳务汇总）
  const laborEstimatedSettlementAmount = getLaborEstimatedSettlementAmount(
    laborBudgets,
    laborContracts
  );
  // 预计结算金额（材料汇总）
  const materialEstimatedSettlementAmount =
    getMaterialEstimatedSettlementAmount(
      materialBudgets,
      materialContracts,
      trivialContracts
    );
  // 预计结算金额（专业分包汇总）
  const subContractEstimatedSettlementAmount =
    getSubContractEstimatedSettlementAmount(subBudgets, subContracts);

  // 预计结算金额（措施费汇总）
  const otherEstimatedSettlementAmount = getOtherEstimatedSettlementAmount(
    otherBudgets,
    others
  );

  // 累计产值(专业分包汇总)
  const subPayAccumulatedMap = subAccumulatedMap(subContractPayMap);
  // 获取所有材料的id
  const subContractIds = Array.from(
    new Set([...Object.keys(subPayAccumulatedMap)])
  );
  const subDic: { [key: string]: number } = {};
  subContractIds?.map((id) => {
    const max1 = subPayAccumulatedMap?.[id] || 0;

    subDic[id] = Math.max(max1);
  });
  const zyfbljcz = Object.values(subDic)?.reduce(sum, 0);

  // 实批金额(专业分包汇总)

  const zyfbspje = Object.values(subContractPayMap)
    ?.flatMap((array) => array)
    ?.map((data) => Number(data.bcpkje))
    ?.filter((data) => !!data)
    ?.reduce(sum, 0);

  // 实付金额(专业分包汇总)
  const zyfbsfje = getSubActualPaymentAmountSum(
    subContractPaymentArray?.filter((data) => data.fklx === "支付类") || []
  );

  // 累计产值(措施费)
  const csfljcz = Object.values(remediationCostPayMap)
    ?.flatMap((array) => array)
    ?.map((data) => data.bcfkje)
    ?.reduce(sum, 0);

  // 实付金额(开办措施费汇总)
  const csfsfje =
    remediationCostPaymentArray?.map((data) => data.bcfkje)?.reduce(sum, 0) ||
    0;

  // 更换项目
  const onChangeProject = (projectObjectId: SelectValue) => {
    setCurrentObjectId(String(projectObjectId));
  };
  // 切换头部显示
  const onToggleShow = () => {
    setShowHead(!showHead);
  };

  // 项目总成本
  const totalProjectCost =
    laborEstimatedSettlementAmount +
    materialEstimatedSettlementAmount +
    subContractEstimatedSettlementAmount +
    otherEstimatedSettlementAmount;

  return (
    <div className="App">
      <Form className="Form" layout="inline">
        <FormItem label="项目">
          <Select
            value={currentObjectId}
            clearable
            options={projectOptions}
            className="Select"
            onChange={onChangeProject}
          />
        </FormItem>
        <FormItem>
          <Button onClick={onToggleShow}>显示</Button>
        </FormItem>
      </Form>
      <table className="Table">
        {showHead && (
          <Head
            projectArchive={projectArchive}
            totalProjectCost={totalProjectCost}
            contract={djContract}
          />
        )}
        <tbody>
          <tr style={{ position: "sticky", top: 0, backgroundColor: "white" }}>
            <td rowSpan={2}>序号</td>
            <td colSpan={2}>目标成本</td>
            <td colSpan={5}>签订合同</td>
            <td colSpan={maxCount * 2 + 3}>过程支付</td>
            <td rowSpan={2} colSpan={5}>
              完工结算
            </td>
          </tr>
          <tr
            style={{
              position: "sticky",
              top: "40px",
              backgroundColor: "white",
            }}
          >
            <td>科目</td>
            <td>金额</td>
            <td>合同名称</td>
            <td>签约额</td>
            <td>施工范围</td>
            <td>供方名称</td>
            <td>网批号</td>
            <td>科目</td>
            {maxArray?.map((_, index) => {
              return (
                <td key={`max-count-${index}`} colSpan={2}>
                  支付期{index + 1}
                </td>
              );
            })}
            <td>小计</td>
            <td>占比产值</td>
          </tr>
          <tr>
            <td>一</td>
            <td colSpan={2}>劳务类</td>
            <td colSpan={5}></td>
            <td colSpan={maxCount * 2 + 3}></td>
          </tr>
          {laborBudgets?.map((data, index) => {
            const contractObjectId =
              laborContractMap?.[data.ObjectId]?.[0]?.ObjectId;
            // 是否虚拟合同
            const isVirtual = laborContractMap?.[data.ObjectId]?.[0]?.sfxnht;
            // 获取结算对象
            const contractObj = laborSettlementArray?.find(
              (data) => data.glht === contractObjectId
            );
            // 获取质保金退回对象
            const paybackObj = laborPaybackArray?.find(
              (data) => data.glht === contractObjectId
            );
            const paymentObjArray = laborPaymentArray?.filter(
              (data) => data.jslfkdh === contractObj?.ObjectId
            );
            // 过程款数组
            const payArray = laborPayMap[contractObjectId];
            const payAmountArray = payArray?.map((data) => ({
              ObjectId: data.ObjectId,
              Amount: data.bcpk,
              Link: LinkEnum.laborPay,
              AccumulatedValue: data.ljyfwcz,
              AccumulatedPayment: data.ljyfk,
              CreatedTime: new Date(data.CreatedTime).getTime(),
            }));
            const approvedArray: ApprovedVO[] = [
              ...(payAmountArray || []),
            ].sort((a, b) => a.CreatedTime - b.CreatedTime);
            // 最大累计产值
            const accumulatedValue = approvedArray
              ?.map((data) => data.AccumulatedValue)
              ?.sort((a: number, b: number) => b - a)?.[0];
            // 最大累计应付款项
            const accumulatedPayment = approvedArray
              ?.map((data) => data.AccumulatedPayment)
              ?.sort((a: number, b: number) => b - a)?.[0];

            // 根据劳务实付金额数组，首先过滤对应合同，再过滤过程款
            const currentLaborPaymentArray = laborPaymentArray
              ?.filter((data) => data.glht === contractObjectId)
              ?.filter((data) => data.fklx === "支付类");
            const currentLaborSettlementArray = laborPaymentArray
              ?.filter((data) => data.glht === contractObjectId)
              ?.filter((data) => data.fklx === "结算类");
            const currentLaborPaybackArray = laborPaymentArray
              ?.filter((data) => data.glht === contractObjectId)
              ?.filter((data) => data.fklx === "质保金退回");
            // 找到过程类的实付，将其映射为key为实批id，value为数组的形式
            const currentLaborPaymentMap = handleLaborPaymentArrayMap(
              currentLaborPaymentArray
            );
            // 实付汇总数组
            const currentLaborPaymentSumArray = approvedArray?.map((data) => {
              return correct(
                currentLaborPaymentMap[data.ObjectId]
                  ?.map((data: LaborPaymentVO) => data.fkblhs)
                  ?.reduce(sum, 0),
                data.Amount
              );
            });

            // 实付ObjectId二维数组
            const currentLaborPaymentObjectIdArray = approvedArray?.map(
              (data) => {
                return currentLaborPaymentMap[data.ObjectId]
                  ?.sort(
                    (a: LaborPaymentVO, b: LaborPaymentVO) =>
                      new Date(a.CreatedTime).getTime() -
                      new Date(b.CreatedTime).getTime()
                  )
                  ?.map((data: LaborPaymentVO) => data.ObjectId);
              }
            );
            // 实付金额汇总
            const sfjehz = currentLaborPaymentSumArray?.reduce(sum, 0);
            const laborMaxCount =
              maxLaborPaymentCountMap?.[contractObjectId] || 1;

            const laborMaxCountSum =
              laborContractMap?.[data.ObjectId]
                ?.map(
                  (data) => (maxLaborPaymentCountMap?.[data.ObjectId] || 1) - 1
                )
                ?.reduce(sum, 0) || 0;

            const contractRowSpan =
              (laborContractMap?.[data.ObjectId]?.length || 1) * 5 +
              laborMaxCountSum;
            // 单个合同
            const singleContractRowSpan = 5 + laborMaxCount - 1;
            // 合同签约总金额，与预算金额做对比
            const totalAmount = laborContractMap?.[data.ObjectId]
              ?.map((data) => data.htze)
              ?.reduce(sum, 0);
            // 计算每个预算关联的合同总签约额，与预算对比
            const isOverflow =
              laborContractMap?.[data.ObjectId]
                ?.map((data) => data.htze)
                ?.reduce(sum, 0) > data.ysje;

            return (
              <Fragment key={`labor-${index}`}>
                <Row
                  rowSpan={contractRowSpan}
                  index={index + 1}
                  subject={occupationsMap[data.gz]}
                  amount={data.ysje}
                  contractRowSpan={singleContractRowSpan}
                  isVirtual={isVirtual}
                  name={laborContractMap?.[data.ObjectId]?.[0]?.htmc}
                  contractAmount={laborContractMap?.[data.ObjectId]?.[0]?.htze}
                  isOverflow={isOverflow}
                  scope={laborContractMap?.[data.ObjectId]?.[0]?.sgfw}
                  supplier={
                    supplierMap[laborContractMap?.[data.ObjectId]?.[0]?.dfztmc]
                  }
                  contractUrl={LinkEnum.laborContract}
                  contractId={laborContractMap?.[data.ObjectId]?.[0]?.ObjectId}
                  maxArray={maxArray}
                  accumulatedValue={accumulatedValue}
                  settlementAmount={contractObj?.jsje || 0}
                  accumulatedPayment={accumulatedPayment}
                  settlementProportion={contractObj?.ljyfk || 0}
                  approvedAmountArray={approvedArray?.map(
                    (data) => data?.Amount
                  )}
                  approvedArraySum={approvedArray
                    ?.map((data) => data?.Amount)
                    ?.reduce(sum, 0)}
                  leftAmount={contractObj?.syyzfje || 0}
                  guaranteeBond={contractObj?.zfjyl || 0}
                  actualAmountArray={currentLaborPaymentSumArray}
                  actualArraySum={sfjehz}
                  settlementActualAmount={paymentObjArray
                    ?.map((data) => data.bcfkje)
                    ?.reduce(sum, 0)}
                  paybackGuaranteeBond={currentLaborPaybackArray
                    ?.map((data) => data.bcfkje)
                    ?.reduce(sum, 0)}
                  actualMaxCount={laborMaxCount || 1}
                  approvedObjectIdArray={approvedArray?.map(
                    (data) => data.ObjectId
                  )}
                  approvedUrlArray={approvedArray?.map((data) => data.Link)}
                  actualUrl={LinkEnum.laborPayment}
                  actualObjectIdArray={currentLaborPaymentObjectIdArray}
                  settlementObjectId={contractObj?.ObjectId || ""}
                  settlementUrl={LinkEnum.laborSettlement}
                  paybackObjectId={paybackObj?.ObjectId || ""}
                  paybackUrl={LinkEnum.laborPayback}
                  settlementActualObjectIdArray={currentLaborSettlementArray
                    ?.map((data) => data.ObjectId)
                    ?.reverse()}
                  paybackActualObjectIdArray={currentLaborPaybackArray
                    ?.map((data) => data.ObjectId)
                    ?.reverse()}
                ></Row>
                {(laborContractMap?.[data.ObjectId]?.length || 1) > 1 &&
                  laborContractMap?.[data.ObjectId]
                    ?.slice(1)
                    ?.map((contract, i) => {
                      // 获取结算对象
                      const contractObj = laborSettlementArray?.find(
                        (data) => data.glht === contract.ObjectId
                      );
                      // 获取质保金退回对象
                      const paybackObj = laborPaybackArray?.find(
                        (data) => data.glht === contract.ObjectId
                      );
                      const paymentObjArray = laborPaymentArray?.filter(
                        (data) => data.jslfkdh === contractObj?.ObjectId
                      );
                      // 过程款数组
                      const payArray = laborPayMap[contract.ObjectId];
                      const payAmountArray = payArray?.map((data) => ({
                        ObjectId: data.ObjectId,
                        Amount: data.bcpk,
                        Link: LinkEnum.laborPay,
                        AccumulatedValue: data.ljyfwcz,
                        AccumulatedPayment: data.ljyfk,
                        CreatedTime: new Date(data.CreatedTime).getTime(),
                      }));
                      const approvedArray: ApprovedVO[] = [
                        ...(payAmountArray || []),
                      ].sort((a, b) => a.CreatedTime - b.CreatedTime);
                      // 最大累计产值
                      const accumulatedValue = approvedArray
                        ?.map((data) => data.AccumulatedValue)
                        ?.sort((a: number, b: number) => b - a)?.[0];
                      // 最大累计应付款项
                      const accumulatedPayment = approvedArray
                        ?.map((data) => data.AccumulatedPayment)
                        ?.sort((a: number, b: number) => b - a)?.[0];
                      // 根据劳务实付金额数组，首先过滤对应合同，再过滤过程款
                      const currentLaborPaymentArray = laborPaymentArray
                        ?.filter((data) => data.glht === contract.ObjectId)
                        ?.filter((data) => data.fklx === "支付类");
                      const currentLaborSettlementArray = laborPaymentArray
                        ?.filter((data) => data.glht === contract.ObjectId)
                        ?.filter((data) => data.fklx === "结算类");
                      const currentLaborPaybackArray = laborPaymentArray
                        ?.filter((data) => data.glht === contract.ObjectId)
                        ?.filter((data) => data.fklx === "质保金退回");
                      // 找到过程类的实付，将其映射为key为实批id，value为数组的形式
                      const currentLaborPaymentMap = handleLaborPaymentArrayMap(
                        currentLaborPaymentArray
                      );
                      // 实付汇总数组
                      const currentLaborPaymentSumArray = approvedArray?.map(
                        (data) => {
                          return correct(
                            currentLaborPaymentMap[data.ObjectId]
                              ?.map((data: LaborPaymentVO) => data.fkblhs)
                              ?.reduce(sum, 0),
                            data.Amount
                          );
                        }
                      );
                      // 实付ObjectId二维数组
                      const currentLaborPaymentObjectIdArray =
                        approvedArray?.map((data) => {
                          return currentLaborPaymentMap[data.ObjectId]
                            ?.sort(
                              (a: LaborPaymentVO, b: LaborPaymentVO) =>
                                new Date(a.CreatedTime).getTime() -
                                new Date(b.CreatedTime).getTime()
                            )
                            ?.map((data: LaborPaymentVO) => data.ObjectId);
                        });
                      // 实付金额汇总
                      const sfjehz = currentLaborPaymentSumArray?.reduce(
                        sum,
                        0
                      );
                      const laborMaxCount =
                        maxLaborPaymentCountMap?.[contract.ObjectId] || 0;
                      return (
                        <SubRow
                          key={`sub-labor-${i}`}
                          name={contract?.htmc}
                          isVirtual={contract?.sfxnht}
                          contractAmount={contract?.htze}
                          isOverflow={isOverflow}
                          scope={contract?.sgfw}
                          supplier={supplierMap[contract.dfztmc]}
                          contractUrl={LinkEnum.laborContract}
                          contractId={contract?.ObjectId}
                          maxArray={maxArray}
                          accumulatedValue={accumulatedValue}
                          settlementAmount={contractObj?.jsje || 0}
                          accumulatedPayment={accumulatedPayment}
                          settlementProportion={contractObj?.ljyfk || 0}
                          approvedAmountArray={approvedArray?.map(
                            (data) => data.Amount
                          )}
                          approvedArraySum={approvedArray
                            ?.map((data) => data?.Amount)
                            ?.reduce(sum, 0)}
                          leftAmount={contractObj?.syyzfje || 0}
                          guaranteeBond={contractObj?.zfjyl || 0}
                          actualAmountArray={currentLaborPaymentSumArray}
                          actualArraySum={sfjehz}
                          settlementActualAmount={paymentObjArray
                            ?.map((data) => data.bcfkje)
                            ?.reduce(sum, 0)}
                          paybackGuaranteeBond={currentLaborPaybackArray
                            ?.map((data) => data.bcfkje)
                            ?.reduce(sum, 0)}
                          actualMaxCount={laborMaxCount || 1}
                          approvedObjectIdArray={approvedArray?.map(
                            (data) => data.ObjectId
                          )}
                          approvedUrlArray={approvedArray?.map(
                            (data) => data.Link
                          )}
                          actualUrl={LinkEnum.laborPayment}
                          actualObjectIdArray={currentLaborPaymentObjectIdArray}
                          settlementObjectId={contractObj?.ObjectId || ""}
                          settlementUrl={LinkEnum.laborSettlement}
                          paybackObjectId={paybackObj?.ObjectId || ""}
                          paybackUrl={LinkEnum.laborPayback}
                          settlementActualObjectIdArray={currentLaborSettlementArray
                            ?.map((data) => data.ObjectId)
                            ?.reverse()}
                          paybackActualObjectIdArray={currentLaborPaybackArray
                            ?.map((data) => data.ObjectId)
                            ?.reverse()}
                        />
                      );
                    })}
              </Fragment>
            );
          })}
          <tr>
            <td rowSpan={3}></td>
            <td rowSpan={3}>劳务小计</td>
            <td rowSpan={3}>{budgetSum1?.toLocaleString()}</td>
            <td rowSpan={3}></td>
            <td style={isRedHighlight(contractSum1 > budgetSum1)} rowSpan={3}>
              {contractSum1?.toLocaleString()}
            </td>
            <td rowSpan={3}></td>
            <td rowSpan={3}></td>
            <td rowSpan={3}></td>
            <td>累计产值</td>
            <td colSpan={maxCount * 2}></td>
            <td>{laborAccumulatedValue?.toLocaleString()}</td>
            <td></td>
            <td>预计结算金额</td>
            <td colSpan={4}>
              {laborEstimatedSettlementAmount?.toLocaleString()}
            </td>
          </tr>
          <tr>
            <td>实批金额</td>
            <td colSpan={maxCount * 2}></td>
            <td>{laborActualValue?.toLocaleString()}</td>
            <td>{division(laborActualValue, laborAccumulatedValue)}</td>
            <td>目标成本金额</td>
            <td colSpan={4}>{budgetSum1?.toLocaleString()}</td>
          </tr>
          <tr>
            <td>实付金额</td>
            <td colSpan={maxCount * 2}></td>
            <td>{lwsfje?.toLocaleString()}</td>
            <td>{division(lwsfje, laborAccumulatedValue)}</td>
            <td>节、超金额</td>
            <td colSpan={4}>
              {(
                (laborEstimatedSettlementAmount || 0) - (budgetSum1 || 0)
              )?.toLocaleString()}
            </td>
          </tr>
          <tr></tr>
          <tr>
            <td>二</td>
            <td colSpan={2}>材料类</td>
          </tr>
          {materialBudgets?.map((data, index) => {
            const contractObjectId =
              materialContractMap?.[data.ObjectId]?.[0]?.ObjectId;
            // 是否是虚拟合同
            const isVirtual = materialContractMap?.[data.ObjectId]?.[0]?.sfxnht;
            // 获取结算对象
            const settlementObj = materialSettlementArray?.find(
              (data) => data.glht === contractObjectId
            );
            // 获取质保金退回对象
            const paybackObj = materialPaybackArray?.find(
              (data) => data.glht === contractObjectId
            );
            const paymentObjArray = materialPaymentArray?.filter(
              (data) => data.jslfkdh === settlementObj?.ObjectId
            );

            const contracts = materialContractMap?.[data.ObjectId];
            const contract = contracts?.[0];

            const payArray = materialPayMap[contractObjectId];
            const payAmountArray = payArray?.map((data) => ({
              ObjectId: data.ObjectId,
              Amount: data.bcpkje,
              Link: LinkEnum.materialPay,
              AccumulatedValue: data.ljqrshje,
              AccumulatedPayment: data.yfyfk || data.yfkje,
              CreatedTime: new Date(data.CreatedTime).getTime(),
            }));
            // 零星采购数组
            const trivialArray = trivialContractMap?.[data.ObjectId];
            const trivialAmountArray = trivialArray?.map((data) => ({
              ObjectId: data.ObjectId,
              Amount: data.bcfkje,
              Link: LinkEnum.materialTrivial,
              AccumulatedValue: 0,
              AccumulatedPayment: 0,
              CreatedTime: new Date(data.CreatedTime).getTime(),
            }));
            const approvedArray: ApprovedVO[] = [
              ...(payAmountArray || []),
              ...(trivialAmountArray || []),
            ].sort((a, b) => a.CreatedTime - b.CreatedTime);

            // 最大累计产值
            const accumulatedValue = approvedArray
              ?.map((data) => data.AccumulatedValue)
              ?.sort((a: number, b: number) => b - a)?.[0];
            // 最大累计应付款项
            const accumulatedPayment = approvedArray
              ?.map((data) => data.AccumulatedPayment)
              ?.sort((a: number, b: number) => b - a)?.[0];
            // 零星采购的Id数组
            // 根据材料实付金额数组，首先过滤对应合同，再过滤过程款
            const currentMaterialPaymentArray = materialPaymentArray
              ?.filter(
                (data) =>
                  data.glht === contractObjectId ||
                  trivialArray
                    ?.map((data) => data.ObjectId)
                    ?.indexOf(data.lxclsqdh) !== -1
              )
              ?.filter(
                (data) => data.fklx === "支付类" || data.fklx === "零星材料类"
              );
            const currentMaterialSettlementArray = materialPaymentArray
              ?.filter((data) => data.glht === contractObjectId)
              ?.filter((data) => data.fklx === "结算类");
            const currentMaterialPaybackArray = materialPaymentArray
              ?.filter((data) => data.glht === contractObjectId)
              ?.filter((data) => data.fklx === "质保金退回");
            // 找到过程类的实付，将其映射为key为实批id，value为数组的形式
            const currentMaterialPaymentMap = handleMaterialPaymentArrayMap(
              currentMaterialPaymentArray || []
            );

            // 实付汇总数组
            const currentMaterialPaymentSumArray = approvedArray?.map(
              (data) => {
                return correct(
                  currentMaterialPaymentMap?.[data.ObjectId]
                    ?.map((data: MaterialPaymentVO) =>
                      data.fklx === "支付类" ? data.fkblhs : data.bcfkje
                    )
                    ?.reduce(sum, 0),
                  data.Amount
                );
              }
            );

            // 实付ObjectId二维数组
            const currentMaterialPaymentObjectIdArray = approvedArray?.map(
              (data) => {
                return currentMaterialPaymentMap[data.ObjectId]
                  ?.sort(
                    (a: MaterialPaymentVO, b: MaterialPaymentVO) =>
                      new Date(a.CreatedTime).getTime() -
                      new Date(b.CreatedTime).getTime()
                  )
                  ?.map((data: MaterialPaymentVO) => data.ObjectId);
              }
            );
            // 零星最大次数
            let trivialMax = 0;
            if (trivialContractMap[data.ObjectId]) {
              // 零星支付期, 将结算数组映射到对应的支付期(二维数组)
              const array = trivialContractMap[data.ObjectId]
                ?.map((data) =>
                  currentMaterialPaymentArray?.filter(
                    (payment) => payment.lxclsqdh === data.ObjectId
                  )
                )
                ?.map((arr) => arr?.length || 0);
              trivialMax = Math.max(...array, 1);
            }

            const materialMaxCount = trivialContractMap[data.ObjectId]
              ? trivialMax || 1
              : maxMaterialPaymentCountMap?.[contractObjectId] || 1;

            const materialMaxCountSum =
              materialContractMap?.[data.ObjectId]
                ?.map(
                  (data) =>
                    (maxMaterialPaymentCountMap?.[data.ObjectId] || 1) - 1
                )
                ?.reduce(sum, 0) || 0;
            // 单个合同
            const singleContractRowSpan = 5 + materialMaxCount - 1;

            const contractRowSpan =
              (materialContractMap?.[data.ObjectId]?.length || 1) * 5 +
              (trivialContractMap[data.ObjectId]
                ? trivialMax - 1
                : materialMaxCountSum);
            // 计算每个预算关联的合同总签约额，与预算对比
            const isOverflow =
              materialContractMap?.[data.ObjectId]
                ?.map((data) => data.htze)
                ?.reduce(sum, 0) > data.clysje;
            return (
              <Fragment key={`material-${index}`}>
                <Row
                  rowSpan={contractRowSpan}
                  index={index + 1}
                  subject={materialMap[data.cl]}
                  amount={data.clysje}
                  contractRowSpan={singleContractRowSpan}
                  isVirtual={isVirtual}
                  name={contract?.htmc}
                  contractAmount={contract?.htze}
                  isOverflow={isOverflow}
                  scope={materialContractMap?.[data.ObjectId]?.[0]?.sgfw}
                  supplier={supplierMap[contract?.dfztmc]}
                  contractUrl={LinkEnum.materialContract}
                  contractId={contractObjectId}
                  maxArray={maxArray}
                  accumulatedValue={accumulatedValue}
                  settlementAmount={settlementObj?.jsje || 0}
                  accumulatedPayment={accumulatedPayment}
                  settlementProportion={settlementObj?.ljyfk || 0}
                  approvedAmountArray={approvedArray?.map(
                    (data) => data?.Amount
                  )}
                  approvedArraySum={approvedArray
                    ?.map((data) => data?.Amount)
                    ?.reduce(sum, 0)}
                  leftAmount={settlementObj?.syyzfje || 0}
                  guaranteeBond={settlementObj?.zbjyl || 0}
                  actualAmountArray={currentMaterialPaymentSumArray}
                  actualArraySum={currentMaterialPaymentSumArray?.reduce(
                    sum,
                    0
                  )}
                  settlementActualAmount={
                    paymentObjArray
                      ?.map((data) => data.bcfkje)
                      ?.reduce(sum, 0) || 0
                  }
                  paybackGuaranteeBond={currentMaterialPaybackArray
                    ?.map((data) => data.bcfkje)
                    ?.reduce(sum, 0)}
                  actualMaxCount={materialMaxCount}
                  approvedObjectIdArray={approvedArray?.map(
                    (data) => data.ObjectId
                  )}
                  approvedUrlArray={approvedArray?.map((data) => data.Link)}
                  actualUrl={LinkEnum.materialPayment}
                  actualObjectIdArray={currentMaterialPaymentObjectIdArray}
                  settlementObjectId={settlementObj?.ObjectId || ""}
                  settlementUrl={LinkEnum.materialSettlement}
                  paybackObjectId={paybackObj?.ObjectId || ""}
                  paybackUrl={LinkEnum.materialPayback}
                  settlementActualObjectIdArray={(
                    currentMaterialSettlementArray?.map(
                      (data) => data.ObjectId
                    ) || []
                  )?.reverse()}
                  paybackActualObjectIdArray={(
                    currentMaterialPaybackArray?.map((data) => data.ObjectId) ||
                    []
                  )?.reverse()}
                />
                {(contracts?.length || 1) > 1 &&
                  contracts?.slice(1)?.map((contract, i) => {
                    // 获取结算对象
                    const settlementObj = materialSettlementArray?.find(
                      (data) => data.glht === contract?.ObjectId
                    );
                    // 获取质保金退回对象
                    const paybackObj = materialPaybackArray?.find(
                      (data) => data.glht === contract?.ObjectId
                    );
                    const paymentObjArray = materialPaymentArray?.filter(
                      (data) => data.jslfkdh === settlementObj?.ObjectId
                    );
                    const payArray = materialPayMap[contract?.ObjectId];
                    const payAmountArray = payArray?.map((data) => ({
                      ObjectId: data.ObjectId,
                      Amount: data.bcpkje,
                      Link: LinkEnum.materialPay,
                      AccumulatedValue: data.ljqrshje,
                      AccumulatedPayment: data.yfyfk,
                      CreatedTime: new Date(data.CreatedTime).getTime(),
                    }));
                    const approvedArray: ApprovedVO[] = [
                      ...(payAmountArray || []),
                    ].sort((a, b) => a.CreatedTime - b.CreatedTime);
                    // 最大累计产值
                    const accumulatedValue = approvedArray
                      ?.map((data) => data.AccumulatedValue)
                      ?.sort((a: number, b: number) => b - a)?.[0];
                    // 最大累计应付款项
                    const accumulatedPayment = approvedArray
                      ?.map((data) => data.AccumulatedPayment)
                      ?.sort((a: number, b: number) => b - a)?.[0];
                    // 根据材料实付金额数组，首先过滤对应合同，再过滤过程款
                    const currentMaterialPaymentArray = materialPaymentArray
                      ?.filter((data) => data.glht === contract?.ObjectId)
                      ?.filter((data) => data.fklx === "支付类");

                    const currentMaterialSettlementArray = materialPaymentArray
                      ?.filter((data) => data.glht === contract?.ObjectId)
                      ?.filter((data) => data.fklx === "结算类");
                    const currentMaterialPaybackArray = materialPaymentArray
                      ?.filter((data) => data.glht === contract?.ObjectId)
                      ?.filter((data) => data.fklx === "质保金退回");
                    // 找到过程类的实付，将其映射为key为实批id，value为数组的形式
                    const currentMaterialPaymentMap =
                      handleMaterialPaymentArrayMap(
                        currentMaterialPaymentArray || []
                      );
                    // 实付汇总数组
                    const currentMaterialPaymentSumArray = approvedArray?.map(
                      (data) => {
                        return correct(
                          currentMaterialPaymentMap?.[data.ObjectId]
                            ?.map((data: MaterialPaymentVO) =>
                              data.fklx === "支付类" ? data.fkblhs : data.bcfkje
                            )
                            ?.reduce(sum, 0),
                          data.Amount
                        );
                      }
                    );
                    // 实付ObjectId二维数组
                    const currentMaterialPaymentObjectIdArray =
                      approvedArray?.map((data) => {
                        return currentMaterialPaymentMap[data.ObjectId]
                          ?.sort(
                            (a: MaterialPaymentVO, b: MaterialPaymentVO) =>
                              new Date(a.CreatedTime).getTime() -
                              new Date(b.CreatedTime).getTime()
                          )
                          ?.map((data: MaterialPaymentVO) => data.ObjectId);
                      });
                    const materialMaxCount =
                      maxMaterialPaymentCountMap?.[contract?.ObjectId] || 1;
                    return (
                      <SubRow
                        key={`sub-material-${i}`}
                        name={contract?.htmc}
                        isVirtual={contract?.sfxnht}
                        contractAmount={contract?.htze}
                        isOverflow={isOverflow}
                        scope={contract?.sgfw}
                        supplier={supplierMap[contract.dfztmc]}
                        contractUrl={LinkEnum.materialContract}
                        contractId={contract?.ObjectId}
                        maxArray={maxArray}
                        accumulatedValue={accumulatedValue}
                        settlementAmount={settlementObj?.jsje || 0}
                        accumulatedPayment={accumulatedPayment}
                        settlementProportion={settlementObj?.ljyfk || 0}
                        approvedAmountArray={approvedArray?.map(
                          (data) => data.Amount
                        )}
                        approvedArraySum={approvedArray
                          ?.map((data) => data?.Amount)
                          ?.reduce(sum, 0)}
                        leftAmount={settlementObj?.syyzfje || 0}
                        guaranteeBond={settlementObj?.zbjyl || 0}
                        actualAmountArray={currentMaterialPaymentSumArray}
                        actualArraySum={currentMaterialPaymentSumArray?.reduce(
                          sum,
                          0
                        )}
                        settlementActualAmount={
                          paymentObjArray
                            ?.map((data) => data.bcfkje)
                            ?.reduce(sum, 0) || 0
                        }
                        paybackGuaranteeBond={currentMaterialPaybackArray
                          ?.map((data) => data.bcfkje)
                          ?.reduce(sum, 0)}
                        actualMaxCount={materialMaxCount}
                        approvedObjectIdArray={approvedArray?.map(
                          (data) => data.ObjectId
                        )}
                        approvedUrlArray={approvedArray?.map(
                          (data) => data.Link
                        )}
                        actualUrl={LinkEnum.materialPayment}
                        actualObjectIdArray={
                          currentMaterialPaymentObjectIdArray
                        }
                        settlementObjectId={settlementObj?.ObjectId || ""}
                        settlementUrl={LinkEnum.materialSettlement}
                        paybackObjectId={paybackObj?.ObjectId || ""}
                        paybackUrl={LinkEnum.materialPayback}
                        settlementActualObjectIdArray={(
                          currentMaterialSettlementArray?.map(
                            (data) => data.ObjectId
                          ) || []
                        )?.reverse()}
                        paybackActualObjectIdArray={(
                          currentMaterialPaybackArray?.map(
                            (data) => data.ObjectId
                          ) || []
                        )?.reverse()}
                      />
                    );
                  })}
              </Fragment>
            );
          })}
          <tr>
            <td rowSpan={3}></td>
            <td rowSpan={3}>材料小计</td>
            <td rowSpan={3}>{budgetSum2?.toLocaleString()}</td>
            <td rowSpan={3}></td>
            <td rowSpan={3}>{contractSum2?.toLocaleString()}</td>
            <td rowSpan={3}></td>
            <td rowSpan={3}></td>
            <td rowSpan={3}></td>
            <td>累计送货</td>
            <td colSpan={maxCount * 2}></td>
            <td>{ljsh?.toLocaleString()}</td>
            <td></td>
            <td>预计结算金额</td>
            <td colSpan={4}>
              {materialEstimatedSettlementAmount?.toLocaleString()}
            </td>
          </tr>
          <tr>
            <td>实批金额</td>
            <td colSpan={maxCount * 2}></td>
            <td>{clspje?.toLocaleString()}</td>
            <td>{division(clspje, ljsh)}</td>
            <td>目标成本金额</td>
            <td colSpan={4}>{budgetSum2?.toLocaleString()}</td>
          </tr>
          <tr>
            <td>实付金额</td>
            <td colSpan={maxCount * 2}></td>
            <td>{clsfje?.toLocaleString()}</td>
            <td>{division(clsfje, ljsh)}</td>
            <td>节、超金额</td>
            <td colSpan={4}>
              {(
                (materialEstimatedSettlementAmount || 0) - (budgetSum2 || 0)
              )?.toLocaleString()}
            </td>
          </tr>
          <tr></tr>
          <tr>
            <td>三</td>
            <td colSpan={2}>专业发包类</td>
          </tr>
          {subBudgets?.map((data, index) => {
            const contractObjectId =
              subContractContractMap?.[data.ObjectId]?.[0]?.ObjectId;
            // 是否虚拟合同
            const isVirtual =
              subContractContractMap?.[data.ObjectId]?.[0]?.sfxnht;
            // 获取结算对象
            const settlementObj = subContractSettlementArray?.find(
              (data) => data.glht === contractObjectId
            );
            // 获取质保金退回对象
            const paybackObj = subContractPaybackArray?.find(
              (data) => data.glht === contractObjectId
            );
            const paymentObjArray = subContractPaymentArray?.filter(
              (data) => data.kslfkdh === settlementObj?.ObjectId
            );
            const payArray = subContractPayMap[contractObjectId];
            const payAmountArray = payArray?.map((data) => ({
              ObjectId: data.ObjectId,
              Amount: data.bcpkje,
              Link: LinkEnum.subContractPay,
              AccumulatedValue: data.ljywccz,
              AccumulatedPayment: data.ljyfzf,
              CreatedTime: new Date(data.CreatedTime).getTime(),
            }));

            const approvedArray: ApprovedVO[] = [
              ...(payAmountArray || []),
            ].sort((a, b) => a.CreatedTime - b.CreatedTime);
            // 最大累计产值
            const accumulatedValue = approvedArray
              ?.map((data) => data.AccumulatedValue)
              ?.sort((a: number, b: number) => b - a)?.[0];
            // 最大累计应付款项
            const accumulatedPayment = approvedArray
              ?.map((data) => data.AccumulatedPayment)
              ?.sort((a: number, b: number) => b - a)?.[0];
            // 根据劳务实付金额数组，首先过滤对应合同，再过滤过程款
            const currentSubContractPaymentArray = subContractPaymentArray
              ?.filter((data) => data.glht === contractObjectId)
              ?.filter((data) => data.fklx === "支付类");
            const currentSubContractSettlementArray = subContractPaymentArray
              ?.filter((data) => data.glht === contractObjectId)
              ?.filter((data) => data.fklx === "结算类");
            const currentSubContractPaybackArray = subContractPaymentArray
              ?.filter((data) => data.glht === contractObjectId)
              ?.filter((data) => data.fklx === "质保金退回");
            // 找到过程类的实付，将其映射为key为实批id，value为数组的形式
            const currentSubContractPaymentMap =
              handleSubContractPaymentArrayMap(
                currentSubContractPaymentArray || []
              );
            // 实付汇总数组
            const currentSubContractPaymentSumArray = approvedArray?.map(
              (data) => {
                return correct(
                  currentSubContractPaymentMap[data.ObjectId]
                    ?.map((data: SubContractPaymentVO) =>
                      data.fklx === "支付类" ? data.fkblhs : data.bcfkje
                    )
                    ?.reduce(sum, 0),
                  data.Amount
                );
              }
            );
            // 实付ObjectId二维数组
            const currentSubContractPaymentObjectIdArray = approvedArray?.map(
              (data) => {
                return currentSubContractPaymentMap[data.ObjectId]
                  ?.sort(
                    (a: SubContractPaymentVO, b: SubContractPaymentVO) =>
                      new Date(a.CreatedTime).getTime() -
                      new Date(b.CreatedTime).getTime()
                  )
                  ?.map((data: SubContractPaymentVO) => data.ObjectId);
              }
            );
            const subContractMaxCount =
              maxSubContractPaymentCountMap?.[contractObjectId] || 1;
            const subContractMaxCountSum =
              subContractContractMap?.[data.ObjectId]
                ?.map(
                  (data) =>
                    (maxSubContractPaymentCountMap?.[data.ObjectId] || 1) - 1
                )
                ?.reduce(sum, 0) || 0;
            const contractRowSpan =
              (subContractContractMap?.[data.ObjectId]?.length || 1) * 5 +
              subContractMaxCountSum;
            // 单个合同
            const singleContractRowSpan = 5 + subContractMaxCount - 1;
            // 计算每个预算关联的合同总签约额，与预算对比
            const isOverflow =
              subContractContractMap?.[data.ObjectId]
                ?.map((data) => data.htze)
                ?.reduce(sum, 0) > data.fbysje;
            return (
              <Fragment key={`sub-contract-${index}`}>
                <Row
                  rowSpan={contractRowSpan}
                  index={index + 1}
                  subject={subContractMap[data.zyfb]}
                  amount={data.fbysje}
                  contractRowSpan={singleContractRowSpan}
                  isVirtual={isVirtual}
                  name={subContractContractMap?.[data.ObjectId]?.[0]?.htmc}
                  contractAmount={
                    subContractContractMap?.[data.ObjectId]?.[0]?.htze
                  }
                  isOverflow={isOverflow}
                  scope={subContractContractMap?.[data.ObjectId]?.[0]?.sgfw}
                  supplier={
                    supplierMap[
                      subContractContractMap?.[data.ObjectId]?.[0]?.dfztmc
                    ]
                  }
                  contractUrl={LinkEnum.subContractContract}
                  contractId={contractObjectId}
                  maxArray={maxArray}
                  accumulatedValue={accumulatedValue}
                  settlementAmount={settlementObj?.jsje || 0}
                  accumulatedPayment={accumulatedPayment}
                  settlementProportion={settlementObj?.ljyfk || 0}
                  approvedAmountArray={approvedArray?.map(
                    (data) => data?.Amount
                  )}
                  approvedArraySum={approvedArray
                    ?.map((data) => data?.Amount)
                    ?.reduce(sum, 0)}
                  leftAmount={settlementObj?.cyyzfje || 0}
                  guaranteeBond={settlementObj?.zbjyl || 0}
                  actualAmountArray={currentSubContractPaymentSumArray}
                  actualArraySum={currentSubContractPaymentSumArray?.reduce(
                    sum,
                    0
                  )}
                  settlementActualAmount={
                    paymentObjArray
                      ?.map((data) => data.bcfkje)
                      ?.reduce(sum, 0) || 0
                  }
                  paybackGuaranteeBond={currentSubContractPaybackArray
                    ?.map((data) => data.bcfkje)
                    ?.reduce(sum, 0)}
                  actualMaxCount={subContractMaxCount}
                  approvedObjectIdArray={approvedArray?.map(
                    (data) => data.ObjectId
                  )}
                  approvedUrlArray={approvedArray?.map((data) => data.Link)}
                  actualUrl={LinkEnum.subContractPayment}
                  actualObjectIdArray={currentSubContractPaymentObjectIdArray}
                  settlementObjectId={settlementObj?.ObjectId || ""}
                  settlementUrl={LinkEnum.subContractSettlement}
                  paybackObjectId={paybackObj?.ObjectId || ""}
                  paybackUrl={LinkEnum.subContractPayback}
                  settlementActualObjectIdArray={(
                    currentSubContractSettlementArray?.map(
                      (data) => data.ObjectId
                    ) || []
                  )?.reverse()}
                  paybackActualObjectIdArray={(
                    currentSubContractPaybackArray?.map(
                      (data) => data.ObjectId
                    ) || []
                  )?.reverse()}
                />
                {(subContractContractMap?.[data.ObjectId]?.length || 1) > 1 &&
                  subContractContractMap?.[data.ObjectId]
                    ?.slice(1)
                    ?.map((contract, i) => {
                      // 获取结算对象
                      const settlementObj = subContractSettlementArray?.find(
                        (data) => data.glht === contract?.ObjectId
                      );
                      // 获取质保金退回对象
                      const paybackObj = subContractPaybackArray?.find(
                        (data) => data.glht === contract?.ObjectId
                      );
                      const paymentObjArray = subContractPaymentArray?.filter(
                        (data) => data.kslfkdh === settlementObj?.ObjectId
                      );

                      const payArray = subContractPayMap[contractObjectId];
                      const payAmountArray = payArray?.map((data) => ({
                        ObjectId: data.ObjectId,
                        Amount: data.bcpkje,
                        Link: LinkEnum.subContractPay,
                        AccumulatedValue: data.ljywccz,
                        AccumulatedPayment: data.ljyfzf,
                        CreatedTime: new Date(data.CreatedTime).getTime(),
                      }));

                      const approvedArray: ApprovedVO[] = [
                        ...(payAmountArray || []),
                      ].sort((a, b) => a.CreatedTime - b.CreatedTime);
                      // 最大累计产值
                      const accumulatedValue = approvedArray
                        ?.map((data) => data.AccumulatedValue)
                        ?.sort((a: number, b: number) => b - a)?.[0];
                      // 最大累计应付款项
                      const accumulatedPayment = approvedArray
                        ?.map((data) => data.AccumulatedPayment)
                        ?.sort((a: number, b: number) => b - a)?.[0];
                      // 根据劳务实付金额数组，首先过滤对应合同，再过滤过程款
                      const currentSubContractPaymentArray =
                        subContractPaymentArray
                          ?.filter((data) => data.glht === contract?.ObjectId)
                          ?.filter((data) => data.fklx === "支付类");
                      const currentSubContractSettlementArray =
                        subContractPaymentArray
                          ?.filter((data) => data.glht === contract?.ObjectId)
                          ?.filter((data) => data.fklx === "结算类");
                      const currentSubContractPaybackArray =
                        subContractPaymentArray
                          ?.filter((data) => data.glht === contract?.ObjectId)
                          ?.filter((data) => data.fklx === "质保金退回");
                      // 找到过程类的实付，将其映射为key为实批id，value为数组的形式
                      const currentSubContractPaymentMap =
                        handleSubContractPaymentArrayMap(
                          currentSubContractPaymentArray || []
                        );
                      // 实付汇总数组
                      const currentSubContractPaymentSumArray =
                        approvedArray?.map((data) => {
                          return correct(
                            currentSubContractPaymentMap[data.ObjectId]
                              ?.map((data: SubContractPaymentVO) =>
                                data.fklx === "支付类"
                                  ? data.fkblhs
                                  : data.bcfkje
                              )
                              ?.reduce(sum, 0),
                            data.Amount
                          );
                        });
                      // 实付ObjectId二维数组
                      const currentSubContractPaymentObjectIdArray =
                        approvedArray?.map((data) => {
                          return currentSubContractPaymentMap[data.ObjectId]
                            ?.sort(
                              (
                                a: SubContractPaymentVO,
                                b: SubContractPaymentVO
                              ) =>
                                new Date(a.CreatedTime).getTime() -
                                new Date(b.CreatedTime).getTime()
                            )
                            ?.map(
                              (data: SubContractPaymentVO) => data.ObjectId
                            );
                        });
                      const subContractMaxCount =
                        maxSubContractPaymentCountMap?.[contract?.ObjectId] ||
                        1;
                      return (
                        <SubRow
                          key={`sub-sub-contract-${i}`}
                          isVirtual={isVirtual}
                          name={contract?.htmc}
                          contractAmount={contract?.htze}
                          isOverflow={isOverflow}
                          scope={contract?.sgfw}
                          supplier={supplierMap[contract?.dfztmc]}
                          contractUrl={LinkEnum.subContractContract}
                          contractId={contract?.ObjectId}
                          maxArray={maxArray}
                          accumulatedValue={accumulatedValue}
                          settlementAmount={settlementObj?.jsje || 0}
                          accumulatedPayment={accumulatedPayment}
                          settlementProportion={settlementObj?.ljyfk || 0}
                          approvedAmountArray={approvedArray?.map(
                            (data) => data?.Amount
                          )}
                          approvedArraySum={approvedArray
                            ?.map((data) => data?.Amount)
                            ?.reduce(sum, 0)}
                          leftAmount={settlementObj?.cyyzfje || 0}
                          guaranteeBond={settlementObj?.zbjyl || 0}
                          actualAmountArray={currentSubContractPaymentSumArray}
                          actualArraySum={currentSubContractPaymentSumArray?.reduce(
                            sum,
                            0
                          )}
                          settlementActualAmount={
                            paymentObjArray
                              ?.map((data) => data.bcfkje)
                              ?.reduce(sum, 0) || 0
                          }
                          paybackGuaranteeBond={currentSubContractPaybackArray
                            ?.map((data) => data.bcfkje)
                            ?.reduce(sum, 0)}
                          actualMaxCount={subContractMaxCount}
                          approvedObjectIdArray={approvedArray?.map(
                            (data) => data.ObjectId
                          )}
                          approvedUrlArray={approvedArray?.map(
                            (data) => data.Link
                          )}
                          actualUrl={LinkEnum.subContractPayment}
                          actualObjectIdArray={
                            currentSubContractPaymentObjectIdArray
                          }
                          settlementObjectId={settlementObj?.ObjectId || ""}
                          settlementUrl={LinkEnum.subContractSettlement}
                          paybackObjectId={paybackObj?.ObjectId || ""}
                          paybackUrl={LinkEnum.subContractPayback}
                          settlementActualObjectIdArray={(
                            currentSubContractSettlementArray?.map(
                              (data) => data.ObjectId
                            ) || []
                          )?.reverse()}
                          paybackActualObjectIdArray={(
                            currentSubContractPaybackArray?.map(
                              (data) => data.ObjectId
                            ) || []
                          )?.reverse()}
                        />
                      );
                    })}
              </Fragment>
            );
          })}
          <tr>
            <td rowSpan={3}></td>
            <td rowSpan={3}>专业发包小计</td>
            <td rowSpan={3}>{budgetSum3?.toLocaleString()}</td>
            <td rowSpan={3}></td>
            <td rowSpan={3}>{contractSum3?.toLocaleString()}</td>
            <td rowSpan={3}></td>
            <td rowSpan={3}></td>
            <td rowSpan={3}></td>
            <td>累计产值</td>
            <td colSpan={maxCount * 2}></td>
            <td>{zyfbljcz?.toLocaleString()}</td>
            <td></td>
            <td>预计结算金额</td>
            <td colSpan={4}>
              {subContractEstimatedSettlementAmount?.toLocaleString()}
            </td>
          </tr>
          <tr>
            <td>实批金额</td>
            <td colSpan={maxCount * 2}></td>
            <td>{zyfbspje?.toLocaleString()}</td>
            <td>{division(zyfbspje, zyfbljcz)}</td>
            <td>目标成本金额</td>
            <td colSpan={4}>{budgetSum3?.toLocaleString()}</td>
          </tr>
          <tr>
            <td>实付金额</td>
            <td colSpan={maxCount * 2}></td>
            <td>{zyfbsfje?.toLocaleString()}</td>
            <td>{division(zyfbsfje, zyfbljcz)}</td>
            <td>节、超金额</td>
            <td colSpan={4}>
              {(
                (subContractEstimatedSettlementAmount || 0) - (budgetSum3 || 0)
              )?.toLocaleString()}
            </td>
          </tr>
          <tr></tr>
          <tr>
            <td>四</td>
            <td colSpan={2}>开办、措施类</td>
          </tr>
          {otherBudgets?.map((data, index) => {
            const rcMaxCount = maxRCPaymentCountMap?.[data.ObjectId] || 1;
            const rowSpan = 3 + rcMaxCount - 1;
            // 实批金额数组
            const spjeArray =
              remediationCostPayMap[data.ObjectId]?.sort(
                (a, b) =>
                  new Date(a.CreatedTime).getTime() -
                  new Date(b.CreatedTime).getTime()
              ) || [];
            // 实批（B类）
            // const spjeBArray =
            //   remediationCostBProgressMap[data.ObjectId]?.sort(
            //     (a, b) =>
            //       new Date(a.CreatedTime).getTime() -
            //       new Date(b.CreatedTime).getTime()
            //   ) || [];

            const combineArray = [
              ...spjeArray?.map((data) => ({
                ObjectId: data.ObjectId,
                F0000020: data.D275657fkmx?.[0]?.glys,
                Bcje: data.D275657fkmx?.[0]?.bcje,
                Name: data.D275657fkmx?.[0]?.Name,
                CreatedTime: data.CreatedTime,
              })),
            ];
            // 实批金额汇总
            const spjehz =
              combineArray?.map((data) => data?.Bcje)?.reduce(sum, 0) || 0;
            // 实付金额汇总
            const sfjehz = remediationCostPaymentArray
              ?.filter((_d) => _d?.D275657fkmxb?.[0]?.glys === data.ObjectId)
              ?.map((data) => data.bcfkje)
              ?.reduce(sum, 0);

            const actualObjectArray = [
              ...(remediationCostPayMap[data.ObjectId] || []),
              // ...(remediationCostProgressMap[data.ObjectId] || []),
              // ...(remediationCostBProgressMap[data.ObjectId] || []),
            ];

            return (
              <Fragment key={`other-${index}`}>
                <tr key={index}>
                  <td rowSpan={rowSpan}>{index + 1}</td>
                  <td rowSpan={rowSpan}>
                    <div className="Subject">
                      {remediationCostMap[data.csf]}
                    </div>
                  </td>
                  <td rowSpan={rowSpan}>{data?.csysje?.toLocaleString()}</td>
                  <td rowSpan={rowSpan} colSpan={5}></td>
                  <td>实批金额</td>
                  {maxArray?.map((_, i) => {
                    return (
                      <td key={`other-accumulated-value-${i}`} colSpan={2}>
                        {combineArray?.[i]?.Bcje?.toLocaleString()}
                      </td>
                    );
                  })}
                  <td>{spjehz?.toLocaleString()}</td>
                  <td>{division(spjehz, data?.csysje)}</td>
                </tr>
                <tr>
                  <td>实付金额</td>
                  {maxArray?.map((_, i) => {
                    // 用实批金额的ObjectId来筛选实付金额数组(1对多)
                    const objId = combineArray?.[i]?.ObjectId;
                    // 实付金额数组
                    const sfjeArray = remediationCostPaymentArray
                      ?.filter((data) => data.fksqdhzc === objId)
                      ?.map((data) => data?.bcfkje);
                    return (
                      <td key={`other-accumulated-payment-${i}`} colSpan={2}>
                        {(sfjeArray?.reduce(sum, 0) || "")?.toLocaleString()}
                      </td>
                    );
                  })}
                  <td>{sfjehz?.toLocaleString()}</td>
                  <td>{division(sfjehz, data?.csysje)}</td>
                </tr>
                <tr>
                  <td rowSpan={rcMaxCount}>网批号</td>
                  {maxArray?.map((_, i) => {
                    // 获取措施费支付网批id
                    const objectId = actualObjectArray?.[i]?.ObjectId;
                    const firstActualObjectId =
                      rcPaymentMap?.[objectId]?.[0]?.ObjectId;
                    return (
                      <Fragment key={`other-first-${i}`}>
                        <td rowSpan={rcMaxCount}>
                          <Link
                            objectId={objectId}
                            url={LinkEnum.remediationCostPay}
                            title="关联支付审批"
                          />
                        </td>
                        <td>
                          <Link
                            objectId={firstActualObjectId || ""}
                            url={LinkEnum.remediationCostPayment}
                            title="关联实付回挂"
                          />
                        </td>
                      </Fragment>
                    );
                  })}
                  <td rowSpan={rcMaxCount}></td>
                  <td rowSpan={rcMaxCount}></td>
                </tr>
                {rcMaxCount > 1 &&
                  Array(rcMaxCount - 1)
                    .fill("")
                    ?.map((_, i) => {
                      return (
                        <tr key={`other-more-${i}`}>
                          {maxArray.map((_, j) => {
                            // remediationCostProgressMap是根据措施费（正常）的子表的预算来映射措施费（正常）主表数组，也就是实批数组
                            const objectId = combineArray?.[j]?.ObjectId;

                            const actualObjectId =
                              rcPaymentMap?.[objectId]?.[i + 1]?.ObjectId;
                            return (
                              <td>
                                <Link
                                  objectId={actualObjectId || ""}
                                  url={LinkEnum.remediationCostPayment}
                                  title="关联实付回挂"
                                />
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
              </Fragment>
            );
          })}
          <tr>
            <td rowSpan={3}></td>
            <td rowSpan={3}>开办、措施类小计</td>
            <td rowSpan={3}>{budgetSum4?.toLocaleString()}</td>
            <td rowSpan={3} colSpan={5}></td>
            <td>累计产值</td>
            <td colSpan={maxCount * 2}></td>
            <td>{csfljcz?.toLocaleString()}</td>
            <td></td>
          </tr>
          <tr>
            <td>实批金额</td>
            <td colSpan={maxCount * 2}></td>
            <td>{csfljcz?.toLocaleString()}</td>
            <td>{division(csfljcz, csfljcz)}</td>
          </tr>
          <tr>
            <td>实付金额</td>
            <td colSpan={maxCount * 2}></td>
            <td>{csfsfje?.toLocaleString()}</td>
            <td>{division(csfsfje, csfljcz)}</td>
          </tr>
          <tr></tr>
          <tr>
            <td rowSpan={3}></td>
            <td rowSpan={3}>汇总</td>
            <td rowSpan={3}>{budgetSum?.toLocaleString()}</td>
            <td rowSpan={3} colSpan={5}></td>
            <td>累计产值</td>
            <td colSpan={maxCount * 2}></td>
            <td>
              {(
                laborAccumulatedValue +
                ljsh +
                zyfbljcz +
                csfljcz
              )?.toLocaleString()}
            </td>
            <td></td>
          </tr>
          <tr>
            <td>实批金额</td>
            <td colSpan={maxCount * 2}></td>
            <td>
              {(
                laborActualValue +
                clspje +
                zyfbspje +
                csfljcz
              )?.toLocaleString()}
            </td>
            <td>
              {division(
                laborActualValue + clspje + zyfbspje + csfljcz,
                laborAccumulatedValue + ljsh + zyfbljcz + csfljcz
              )}
            </td>
          </tr>
          <tr>
            <td>实付金额</td>
            <td colSpan={maxCount * 2}></td>
            <td>{(lwsfje + clsfje + zyfbsfje + csfsfje)?.toLocaleString()}</td>
            <td>
              {division(
                lwsfje + clsfje + zyfbsfje + csfsfje,
                laborAccumulatedValue + ljsh + zyfbljcz + csfljcz
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default App;
