import axios from "axios";

const EngineCode = process.env.REACT_APP_ENGINE_CODE;
const EngineSecret = process.env.REACT_APP_ENGINE_SECRET;
// const base = "https://www.h3yun.com"

let base = "";
if (window.location.hostname === "localhost") {
  base = "http://localhost:9999";
} else if (window.location.host === "swz.dev.kai-dian.com") {
  base = "https://swz.dev.kai-dian.com";
} else if (window.location.host === "www.hc-tech.online") {
  base = "https://www.hc-tech.online";
} else {
  // base = "http://150.158.15.247";
  base = "https://choyoin.com.cn/";
}

const request = axios.create({
  headers: {
    "Content-Type": "application/json",
    EngineCode,
    EngineSecret,
  },
});

export const getSingleItem = (params: {
  SchemaCode: string;
  BizObjectId: string;
}) => {
  const defaultParams = { ActionName: "LoadBizObject" };
  return request.post(`${base}/OpenApi/Invoke`, {
    ...defaultParams,
    ...params,
  });
};

export const getItems = (params: any) => {
  const defaultParams = {
    ActionName: "LoadBizObjects",
  };
  let Filter = {
    FromRowNum: 0,
    RequireCount: false,
    ReturnItems: [],
    SortByCollection: [],
    ToRowNum: 500,
    Matcher: { Type: "And", Matchers: [] },
  };
  if (params.Filter) {
    Filter = { ...Filter, ...params.Filter };
  }

  return request.post(`${base}/OpenApi/Invoke`, {
    ...defaultParams,
    ...params,
    Filter: JSON.stringify(Filter),
  });
};
