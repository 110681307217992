export enum SchemaCodeEnum {
  // 我司主体
  wsztSchemaCode = "D275657wszt",
  // 项目建档
  xmjdSchemaCode = "D275657xmjd",
  // 项目预算设立
  xmysslSchemaCode = "D275657xmyusl",
  // 工种分类
  gzflSchemaCode = "D275657gzfl",
  // 材料分类
  clflSchemaCode = "D275657clfl",
  // 专业分包分类
  zyfbSchemaCode = "D275657zyfb",
  // 措施费分类
  csfSchemaCode = "D275657csffl",
  // 其他分类
  qtOther = "D275657qtfl",
  // 合同签订（劳务）
  htqdlwSchemaCode = "D275657lwht",
  // 合同签订（材料）
  htqdclSchemaCode = "D275657htqdcl",
  // 合同签订（专业分包）
  htqdzyfbSchemaCode = "D275657htqdzyfb",
  // 材料零星采购（5万以内）
  cllxcgSchemaCode = "D275657lxcg",
  // 供应商档案
  gysdaSchemaCode = "D275657gysdan",
  // 劳务类支付申请
  lwlzfsqSchemaCode = "D275657lwgckzf",
  // 材料类支付申请
  cllzfsqSchemaCode = "D275657clgckzf",
  // 专业分包类支付申请
  zyfblzfsqSchemaCode = "D275657zyfbgckzf",
  // 措施费支付申请
  csfzfsqSchemaCode = "D275657csfzfzc",
  // 其他费用申请
  qtfysqSchemaCode = "D275657qtfy",
  // 项目结算申请（劳务）
  xmjssqlwSchemaCode = "D275657xmjssqlw",
  // 项目结算申请（材料）
  xmjssqclSchemaCode = "D275657xmjssqcl",
  // 项目结算申请（专业分包）
  xmjssqzyfbSchemaCode = "D275657xmjssqzyfb",
  // 劳务付款登记
  lwfkdjSchemaCode = "D275657lwfkdj",
  // 材料付款登记
  clfkdjSchemaCode = "D275657clfkdj",
  // 专业分包付款登记
  zyfbfkdjSchemaCode = "D275657zyfbfkdj",
  // 措施费付款登记
  csffkdjSchemaCode = "D275657csffkdj",
  // 其他费用付款登记
  qtfyfkdjSchemaCode = "D275657qtfyfkdj",
  // 劳务质保金退回
  lwzbjthSchemaCode = "D275657zbjthlw",
  // 材料质保金退回
  clzbjthSchemaCode = "D275657zbjthcl",
  // 专业分包质保金退回
  zyfbzbjthSchemaCode = "D275657zbjthzyfb",
  // 合同签订、回款、签证、结算
  djSchemaCode = "D275657htjdhkqzjs",
  // 人员库
  rykSchemaCode = "D2850175f5a743eaba24b67b96109ca796c6ffd",
}

export enum FilterOperatorEnum {
  largeThan = 0,
  largeThanOrEqual = 1,
  euqal = 2,
  lessThanOrEqual = 3,
  lessThan = 4,
  notEqual = 5,
  whereIn = 6,
  notWhereIn = 7,
}

export enum LinkEnum {
  laborContract = "D275657lwht",
  materialContract = "D275657htqdcl",
  subContractContract = "D275657htqdzyfb",
  laborPay = "D275657lwgckzf",
  materialPay = "D275657clgckzf",
  subContractPay = "D275657zyfbgckzf",
  materialTrivial = "D275657lxcg",
  remediationCostPay = "D275657csfzfzc",
  otherPay = "D275657qtfy",
  // subContract = "D282311Spufak6fny52tyq7km1ysn8mh3",
  laborPayment = "D275657lwfkdj",
  materialPayment = "D275657clfkdj",
  subContractPayment = "D275657zyfbfkdj",
  remediationCostPayment = "D275657csffkdj",
  otherPayment = "D275657qtfyfkdj",
  laborSettlement = "D275657xmjssqlw",
  materialSettlement = "D275657xmjssqcl",
  subContractSettlement = "D275657xmjssqzyfb",
  laborPayback = "D275657zbjthlw",
  materialPayback = "D275657zbjthcl",
  subContractPayback = "D275657zbjthzyfb",
}
