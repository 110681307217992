import { useEffect, useState } from "react";
import { ProjectArchiveVO, CommonMap, DJContractVO } from "../type";
import { getItems, getSingleItem } from "../services/api";
import { getSchema, handleCommanMap, subtraction } from "../util";
import { SchemaCodeEnum } from "../enum";

function Head({
  projectArchive,
  contract,
  totalProjectCost,
}: {
  projectArchive: ProjectArchiveVO;
  contract: DJContractVO;
  totalProjectCost: number;
}) {
  // 我司主体
  const [companyMap, setCompanyMap] = useState<CommonMap>({} as any);

  // 人员库
  const [personMap, setPersonMap] = useState<CommonMap>({} as any);

  useEffect(() => {
    // 设置我司主体映射
    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.wsztSchemaCode),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      if (array && array.length > 0) {
        setCompanyMap(handleCommanMap(array));
      }
    });

    getItems({
      SchemaCode: getSchema(SchemaCodeEnum.rykSchemaCode),
    }).then(({ data }) => {
      const array = data?.ReturnData?.BizObjectArray;
      if (array && array.length > 0) {
        setPersonMap(handleCommanMap(array));
      }
    });
  }, []);

  return (
    <thead>
      <tr>
        <th>基础数据</th>
        <th>项目经理</th>
        <th>{personMap?.[projectArchive?.F0000002]}</th>
        <th></th>
        <th>项目名称</th>
        <th>{projectArchive?.xmmc}</th>
        <th></th>
        <th>甲方公司</th>
        <th>{projectArchive?.jfzt}</th>
        <th></th>
        <th>项目对甲结算</th>
        <th>{(contract?.xmsjjsje || contract?.xmyjsje)?.toLocaleString()}</th>
      </tr>
      <tr>
        <th></th>
        <th>成本负责人</th>
        <th>{personMap?.[projectArchive?.F0000003]}</th>
        <th></th>
        <th>项目编号</th>
        <th>{projectArchive?.xmbh}</th>
        <th></th>
        <th>乙方公司</th>
        <th>{companyMap?.[projectArchive?.wszt]}</th>
        <th></th>
        <th>项目总成本</th>
        <th>{totalProjectCost?.toLocaleString()}</th>
      </tr>
      <tr>
        <th></th>
        <th>采购负责人</th>
        <th>{personMap?.[projectArchive?.F0000004]}</th>
        <th></th>
        <th>合同金额</th>
        <th>{projectArchive?.htje?.toLocaleString()}</th>
        <th></th>
        <th>业主方</th>
        <th>{projectArchive?.yzf}</th>
        <th></th>
        <th>项目毛利润</th>
        <th>
          {subtraction(
            contract?.xmsjjsje || contract?.xmyjsje,
            totalProjectCost
          )?.toLocaleString()}
        </th>
      </tr>
      <tr>
        <th></th>
        <th>项目会计</th>
        <th>{personMap?.[projectArchive.F0000001]}</th>
        <th></th>
        <th>施工工期</th>
        <th>
          {projectArchive?.jhksrq?.split(" ")?.[0]}~
          {projectArchive?.jhjgrq?.split(" ")?.[0]}
        </th>
      </tr>
      <tr>
        <th colSpan={10}>动态成本控制表</th>
      </tr>
    </thead>
  );
}

export default Head;
