import { Fragment } from "react";
import { LinkEnum } from "../enum";
import { division, isBlueHighlight, isRedHighlight } from "../util";
import Link from "./Link";
import "./SubRow.css";

interface SubRowProps {
  isVirtual: boolean;
  // 合同名称
  name: string;
  // 签约额
  contractAmount: number;
  // 签约额是否超过预算
  isOverflow?: boolean;
  // 施工范围
  scope: string;
  // 供方名称
  supplier: string;
  // 合同跳转url
  contractUrl: LinkEnum;
  // 合同id
  contractId: string;
  // 最大支付期数组
  maxArray: string[];
  // 累计产值
  accumulatedValue: number;
  // 结算金额
  settlementAmount: number;
  // 累计应付款项
  accumulatedPayment: number;
  // 结算比例款项
  settlementProportion: number;
  // 实批金额数组
  approvedAmountArray: number[];
  // 实批金额小计
  approvedArraySum: number;
  // 剩余应付金额
  leftAmount: number;
  // 预留质保金
  guaranteeBond: number;
  // 实付金额数组
  actualAmountArray: number[];
  // 实付金额汇总
  actualArraySum: number;
  // 结算实付金额
  settlementActualAmount: number;
  // 已退质保金
  paybackGuaranteeBond?: number;

  actualMaxCount: number;
  // 实批金额id数组
  approvedObjectIdArray: string[];
  // 关联支付审批url
  approvedUrlArray: LinkEnum[];
  // 关联支付回挂url
  actualUrl: LinkEnum;
  // 关联实付ObjectId数组
  actualObjectIdArray: string[][];
  // 项目结算id
  settlementObjectId: string;
  // 关联结算审批url
  settlementUrl: LinkEnum;
  // 质保金退回id
  paybackObjectId: string;
  // 质保金退回审批url
  paybackUrl: LinkEnum;
  // 关联结算回挂id数组
  settlementActualObjectIdArray: string[];
  // 关联质保金退回回挂id数组
  paybackActualObjectIdArray: string[];
}

function SubRow({
  isVirtual,
  name,
  contractAmount,
  isOverflow,
  scope,
  supplier,
  contractUrl,
  contractId,
  maxArray,
  accumulatedValue,
  settlementAmount,
  accumulatedPayment,
  settlementProportion,
  approvedAmountArray,
  approvedArraySum,
  leftAmount,
  guaranteeBond,
  actualAmountArray,
  actualArraySum,
  settlementActualAmount,
  paybackGuaranteeBond,
  actualMaxCount,
  approvedObjectIdArray,
  approvedUrlArray,
  actualUrl,
  actualObjectIdArray,
  settlementObjectId,
  settlementUrl,
  paybackObjectId,
  paybackUrl,
  settlementActualObjectIdArray,
  paybackActualObjectIdArray,
}: SubRowProps) {
  return (
    <Fragment>
      <tr>
        <td
          className="ContractName"
          style={isBlueHighlight(isVirtual)}
          rowSpan={5 + actualMaxCount - 1}
        >
          {name}
        </td>
        <td
          style={isRedHighlight(isOverflow || false)}
          rowSpan={5 + actualMaxCount - 1}
        >
          {contractAmount?.toLocaleString()}
        </td>
        <td
          className="WorkScope"
          style={isBlueHighlight(isVirtual)}
          rowSpan={5 + actualMaxCount - 1}
        >
          {scope}
        </td>
        <td
          className="SupplierName"
          style={isBlueHighlight(isVirtual)}
          rowSpan={5 + actualMaxCount - 1}
        >
          {supplier}
        </td>
        <td rowSpan={5 + actualMaxCount - 1}>
          <Link objectId={contractId} url={contractUrl} title="关联合同审批" />
        </td>
        <td>累计完成产值</td>
        <td colSpan={maxArray.length * 2}>
          {accumulatedValue?.toLocaleString()}
        </td>
        <td>{accumulatedValue?.toLocaleString()}</td>
        <td></td>
        <td>结算金额</td>
        <td colSpan={4}>{settlementAmount?.toLocaleString()}</td>
      </tr>
      <tr>
        <td>累计应付款项</td>
        <td colSpan={maxArray.length * 2}>
          {accumulatedPayment?.toLocaleString()}
        </td>
        <td>{accumulatedPayment?.toLocaleString()}</td>
        <td>{division(accumulatedPayment, accumulatedValue)}</td>
        <td>结算比例款项</td>
        <td colSpan={4}>{settlementProportion?.toLocaleString()}</td>
      </tr>
      <tr>
        <td>实批金额</td>
        {maxArray?.map((_, i) => {
          return (
            <td colSpan={2} key={`sub-accumulated-value-${i}`}>
              {approvedAmountArray?.[i]?.toLocaleString()}
            </td>
          );
        })}
        <td
          style={isRedHighlight(
            !!accumulatedValue && approvedArraySum > accumulatedValue
          )}
        >
          {approvedArraySum?.toLocaleString()}
        </td>
        <td>{division(approvedArraySum, accumulatedValue)}</td>
        <td>剩余应付金额</td>
        <td colSpan={2}>{leftAmount?.toLocaleString()}</td>
        <td>预留质保金</td>
        <td>{guaranteeBond?.toLocaleString()}</td>
      </tr>
      <tr>
        <td>实付金额</td>
        {maxArray?.map((_, i) => {
          return (
            <td key={`sub-accumulated-payment-${i}`} colSpan={2}>
              {actualAmountArray?.[i]?.toLocaleString()}
            </td>
          );
        })}
        <td>{actualArraySum?.toLocaleString()}</td>
        <td>{division(actualArraySum, accumulatedValue)}</td>
        <td>实付金额</td>
        <td colSpan={2}>{settlementActualAmount?.toLocaleString()}</td>
        <td>已退质保金</td>
        <td>{paybackGuaranteeBond?.toLocaleString()}</td>
      </tr>
      <tr>
        <td rowSpan={actualMaxCount}>网批号</td>
        {maxArray?.map((_, i) => {
          const objectId = approvedObjectIdArray?.[i];
          const firstActualObjectId = actualObjectIdArray?.[i]?.[0];
          return (
            <Fragment key={`sub-first-${i}`}>
              <td rowSpan={actualMaxCount}>
                <Link
                  objectId={objectId}
                  url={approvedUrlArray[i]}
                  title="关联支付审批"
                />
              </td>
              <td>
                <Link
                  objectId={firstActualObjectId}
                  url={actualUrl}
                  title="关联实付回挂"
                />
              </td>
            </Fragment>
          );
        })}
        <td rowSpan={actualMaxCount}></td>
        <td rowSpan={actualMaxCount}></td>
        <td rowSpan={actualMaxCount}>网批号</td>
        <td rowSpan={actualMaxCount}>
          <Link
            objectId={settlementObjectId}
            url={settlementUrl}
            title="关联结算审批"
          />
        </td>
        <td>
          <Link
            objectId={settlementActualObjectIdArray?.[0]}
            url={actualUrl}
            title="关联实付回挂"
          />
        </td>
        <td rowSpan={actualMaxCount}>
          <Link
            objectId={paybackObjectId}
            url={paybackUrl}
            title="质保金退回审批"
          />
        </td>
        <td>
          <Link
            objectId={paybackActualObjectIdArray?.[0]}
            url={actualUrl}
            title="关联实付回挂"
          />
        </td>
      </tr>
      {actualMaxCount > 1 &&
        Array(actualMaxCount - 1)
          .fill("")
          ?.map((_, i) => {
            const currentSettlementObjectId =
              settlementActualObjectIdArray?.[i + 1];
            const currentPaybackObjectId = paybackActualObjectIdArray?.[i + 1];
            return (
              <tr key={`sub-actual-${i}`}>
                {maxArray?.map((_, j) => {
                  const actualObjectId = actualObjectIdArray?.[j]?.[i + 1];
                  return (
                    <td key={`sub-actual-${i}-${j}`}>
                      <Link
                        objectId={actualObjectId}
                        url={actualUrl}
                        title="关联实付回挂"
                      />
                    </td>
                  );
                })}
                <td>
                  <Link
                    objectId={currentSettlementObjectId}
                    url={actualUrl}
                    title="关联实付回挂"
                  />
                </td>
                <td>
                  <Link
                    objectId={currentPaybackObjectId}
                    url={actualUrl}
                    title="关联实付回挂"
                  />
                </td>
              </tr>
            );
          })}
    </Fragment>
  );
}

export default SubRow;
