import { LinkEnum } from "../enum";
import { MouseEvent } from "react";

interface LinkProps {
  objectId: string;
  url: LinkEnum;
  title: string;
}

function Link({ objectId, url, title }: LinkProps) {
  function handleClick(
    url: string,
    objectId: string,
    event: MouseEvent<HTMLAnchorElement>
  ) {
    event.preventDefault();
    const { REACT_APP_BASE_URL, REACT_APP_CODE } = process.env;
    const target = `${REACT_APP_BASE_URL}?id=${url}&code=${REACT_APP_CODE}&BizObjectId=${objectId}`;
    window.open(target, "_blank");
  }

  return objectId ? (
    <a onClick={(e) => handleClick(url, objectId, e)} target="_blank">
      {title}
    </a>
  ) : (
    <></>
  );
}

export default Link;
